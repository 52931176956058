var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dConfig.visible
    ? _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            width: _vm.dConfig.width,
            "close-on-click-modal": false,
            visible: _vm.dConfig.visible,
            title: `${
              (_vm.dConfig.diyEnum || _vm.titleEnum)[_vm.dConfig.type]
            }${_vm.dConfig.name}`,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dConfig, "visible", $event)
            },
            close: _vm.close_handle,
          },
        },
        [
          _c("CreateForm", {
            ref: _vm.dConfig.ref,
            attrs: {
              editData: _vm.dForm.data,
              itemList: _vm.dForm.itemList,
              editConfig: _vm.dForm.config,
              editDisabled:
                _vm.dConfig.type === (_vm.dConfig.disabledFlag || "see"),
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }