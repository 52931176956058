var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fileUploadContainer" },
    [
      _c(
        "el-upload",
        {
          ref: _vm.ids,
          attrs: {
            "element-loading-text": _vm.loadingTextD,
            "element-loading-spinner": "el-icon-loading",
            id: _vm.ids,
            "on-preview": _vm.handlePictureCardPreview,
            "on-remove": _vm.handleRemove,
            "file-list": _vm.fileList,
            accept: _vm.accepted,
            "list-type": "picture-card",
            multiple: _vm.multiple,
            "on-exceed": _vm.handleExceed,
            "before-upload": _vm.handleBeforeUpload,
            "before-remove": _vm.beforeRemove,
            "on-progress": _vm.handleUploadProgress,
            "on-change": _vm.handleChange,
            "auto-upload": true,
            "on-success": _vm.handleSuccess,
            "on-error": _vm.handleError,
            limit: _vm.limit,
            "http-request": _vm.httpRequestLogo,
          },
        },
        [
          [
            _c("i", { staticClass: "el-icon-plus" }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "el-upload__tip",
                attrs: { slot: "tip" },
                slot: "tip",
              },
              [_vm._v("\n      " + _vm._s(_vm.tipsD) + "\n    ")]
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "查看",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("el-image", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.dialogImageUrl, alt: "" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }