var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      ref: "popover",
      attrs: { placement: "right", width: "300", trigger: "click" },
      model: {
        value: _vm.showPopover,
        callback: function ($$v) {
          _vm.showPopover = $$v
        },
        expression: "showPopover",
      },
    },
    [
      _c("div", [
        _c("p", [_vm._v("修改排序")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "sortDiv" },
          [
            _c("el-input", {
              attrs: {
                size: "small",
                type: "number",
                placeholder: "请输入大于0的正整数",
              },
              model: {
                value: _vm.sortInputVal,
                callback: function ($$v) {
                  _vm.sortInputVal = $$v
                },
                expression: "sortInputVal",
              },
            }),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "sortDiv-btn",
                attrs: { size: "mini", disabled: !_vm.sortInputVal },
                on: { click: _vm.sortInputClick },
              },
              [_vm._v("确认")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("el-button", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.closeSort(_vm.row),
            expression: "!closeSort(row)",
          },
        ],
        attrs: {
          slot: "reference",
          type: "text",
          size: "mini",
          icon: "el-icon-edit-outline",
          title: "修改排序",
        },
        slot: "reference",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }