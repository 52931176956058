<template>
  <div class="btn-div">
    <template v-for="(item, index) in optionsList">
      <!-- <Popconfirm
              v-if="item.isConfirm"
              :title="`确定${item.label}该列吗？`"
              placement="top"
              class="delete-popconfirm"
              :key="item.code"
              @confirm="item.method(row, index)"
            >
            <el-button
              v-if="item.showFlag ? item.showFlag(row, index) : true"
              size="mini"
              slot="reference"
              :type="item.type || 'primary'"
              :icon="item.icon"
              :disabled="isDisabled(item)"
            >{{ item.label }}</el-button>
          </Popconfirm>
          <template v-else> -->
      <el-button
        v-if="item.showFlag ? item.showFlag(scope.row, index) : true"
        size="mini"
        class="link-style"
        :class="item.class"
        slot="reference"
        :type="item.type || 'text'"
        :disabled="isDisabled(item)"
        :key="item.code"
        :title="item.title || item.label"
        @click.native.prevent="btnClick(item, index)"
        >{{ item.label }} <i :class="item.icon" class="el-icon--right"></i
      ></el-button>
      <!-- :icon="item.icon" -->
      <!-- </template>-->
    </template>
  </div>
</template>


<script>
import {
  // el-button,
  MessageBox,
  // Popconfirm
} from "element-ui";

import { debounce } from "../../utils/index";

export default {
  name: "renderBtn",
  props: {
    scope: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Array,
      default: () => [],
    },
    dialogConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    // el-button,
    MessageBox,
    // Popconfirm
  },
  data() {
    return {
      optionsList: "", // btn配置列表
    };
  },
  mounted() {
    this.optionsList = this.options;
  },
  methods: {
    /**
     * @description: btn的点击事件
     * @param {*} item 当前配置项的数据
     * @param {*} btnIndex 当前btn的排序
     * @author: huchao
     */
    btnClick(item, btnIndex) {
      const { row, $index } = this.scope;
      const { name } = this.dialogConfig || {};
      if (item.isConfirm) {
        const textStr =
          item.confirmText || `确定${item.label}该${name || "数据"}吗?`;
        MessageBox.confirm(textStr, item.typeText || "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            item.method(row, $index, btnIndex);
          })
          .catch(() => {});
      } else {
        // debounce(item.method(row, $index, btnIndex), 30, true)
        item.method(row, $index, btnIndex);
      }
    },
    /**
     * @description: 判断按钮是否禁用
     * @param { Object } item 当前config配置的按钮信息
     * @return { Boolean } return 一个布尔值
     * @author: huchao
     */
    isDisabled(item) {
      if (typeof item.disabled === "boolean") {
        return item.disabled;
      }
      if (typeof item.disabled === "function") {
        return item.disabled(this.scope);
      }
      return false;
    },
  },
};
</script>

<style scoped>
.link-style {
  font-size: 12px;
  /* margin-right: 8px; */
}
</style>
