<!--
 * @Author:huchao
 * @Date: 2020-07-20 16:00:09
 * @LastEditTime: 2023-06-16 13:08:16
 * @LastEditors: liudong 58164700+ruyanwangshi@users.noreply.github.com
 * @Description: In User Settings Edit
-->
<template>
    <el-dialog
        append-to-body
        :width="dConfig.width"
        :close-on-click-modal="false"
        v-if="dConfig.visible"
        :visible.sync="dConfig.visible"
        @close = "close_handle"
        :title="`${(dConfig.diyEnum || titleEnum)[dConfig.type]}${dConfig.name}`"
    >
        <CreateForm
          :ref="dConfig.ref"
          :editData="dForm.data"
          :itemList="dForm.itemList"
          :editConfig="dForm.config"
          :editDisabled="dConfig.type === (dConfig.disabledFlag || 'see')"
        />
    </el-dialog>
</template>

<script>

export default {
  name: 'DialogForm',
  components: {},
  props: {
    dForm: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    dConfig: {
      type: Object,
      required: true,
      default: (e) => {
        return {
            width: '60%',
            name: '数据',
            type: 'add',
            visible: false,
            form: {},
            // 默认的类型的枚举
            diyEnum: {},
            disabledFlag: 'see', // 在type为see时，需要将表单禁用，默认为see
          }
      }
    }
  },
  watch:{
      'dConfig.visible'(newVal, oldVal) {
          if (newVal) {
            const formObj = this.dConfig.form;
            // 点击新增时，需要将表单重置
            this.dConfig.type === 'add' && formObj.resetFormData(formObj);
          }
      },
      // 'btnLoading'(newVal) {
      //   console.log('newVal', newVal);
      //   const { visible, form, type } = this.dConfig;
      //   console.log('visible', visible);
      //     if (!visible) {
      //       // 点击新增时，需要将表单重置
      //       !newVal && form.resetFormData(form);
      //     }
      // }
  },
  computed: {
    // 表单提交按钮loading
    // btnLoading(){
    //   return this.$store.getters.btnLoading;
    // },
  },
  data() {
    return {
      titleEnum: {
        add: '添加',
        see: '查看',
        update: '修改'
      }, 
    };
  },
  created() {
  },
  mounted() {
    console.log(this.dConfig, 6667);
  },
  methods: {
    close_handle() {
        this.$emit('dialog_close')
    }
  },
};
</script>
<style scoped>

</style>
