<template>
  <!-- <el-tooltip class="item" effect="dark" :content="content" placement="right"> -->
  <el-button
    v-if="row[this.propName]"
    class="copy"
    :data-clipboard-text="row[this.propName]"
    type="text"
    @click="copyClick"
    title="点击复制"
  >
    <i class="el-icon-document-copy"></i>
  </el-button>
  <!-- </el-tooltip> -->
</template>

<script>
import Clipboard from "clipboard";
export default {
  name: "copyBtn",
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    propName: {
      type: String,
      default: () => "",
    },
  },
  components: {},
  data() {
    return {
      content: "点击复制",
    };
  },
  mounted() {},
  methods: {
    /**
     * @description: 点击复制按钮
     * @author: huchao
     */
    copyClick() {
      let clipboard = new Clipboard(".copy");
      clipboard.on("success", () => this.clipboardFinish(clipboard, true));
      clipboard.on("error", () => this.clipboardFinish(clipboard, false));
    },

    /**
     * @description: 复制完成回调
     * @author: huchao
     */
    clipboardFinish(clipboard, flag) {
      const message = `<p style='color: teal;word-break:break-all; word-wrap: break-word;'>${
        this.row[this.propName]
      }</p>`;
      clipboard.destroy();
      this.$notify[flag ? "success" : "error"]({
        message,
        duration: 2000,
        dangerouslyUseHTMLString: true,
        title: `复制${flag ? "成功" : "失败"}`,
      });
    },
  },
};
</script>

<style scoped>
.copy {
  margin-left: 6px;
}
.break-text {
  word-break: break-all;
  word-wrap: break-word;
  color: teal;
}
</style>
