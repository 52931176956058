export default {
  // 查询结算单列表
  findOrderSettleList: {
    method: "post",
    url: "/web/bus/so/settleorder/findOrderSettleList.json",
    type: "json",
  },
  // 查询结算单列表
  findOrderSettleListNew: {
    method: "post",
    url: "/web/so/settleorder/findOrderSettleListsettlementCenter.json",
    ContentType: "multipart/form-data",
    type: "json",
  },
  // 导出功能
  saveUsermfileByMfileCodeBus: {
    method: "post",
    url: "/web/fm/usermfile/saveUsermfileByMfileCodeSettle.json",
    type: "x-www-form",
    white: true, // 当返回的数据结果，与后端统一结构不同时，设置放入白名单
  },
  // 导出功能
  saveUsermfileByMfileCodeMer: {
    method: "post",
    url: "/web/fm/usermfile/saveUsermfileByMfileCodeSettleMer.json",
    type: "x-www-form",
    white: true, // 当返回的数据结果，与后端统一结构不同时，设置放入白名单
  },
  // saveUsermfileByMfileCodeBus: {
  //   method: "post",
  //   url: "/web/fm/usermfile/saveUsermfileByMfileCodeSettle.json",
  //   type: "x-www-form",
  //   white: true, // 当返回的数据结果，与后端统一结构不同时，设置放入白名单
  // },

  saveUsermfileByMfileCodeForListExport: {
    method: "post",
    url: "/web/fm/usermfile/saveUsermfileByMfileCodeForListExport.json",
    type: "x-www-form",
    white: true, // 当返回的数据结果，与后端统一结构不同时，设置放入白名单
  },
};
