const btnLoading = {
  state: {
    btnLoading: false,
    tableLoading: false
  },
  mutations: {
    // 表单提交的btnLoading
    SET_BTN_LOADING: (state, flag) => {
      state.btnLoading = flag;
    },
    // table加载的Loading
    SET_TABLE_LOADING: (state, flag) => {
      state.tableLoading = flag;
    },
  },
  actions: {
    setBtnLoading({ commit }, flag) {
      commit('SET_BTN_LOADING', flag);
    },
    setTableLoading({ commit }, flag) {
      commit('SET_TABLE_LOADING', flag);
    }
  }
}

export default btnLoading;
