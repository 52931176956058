<!--
 * @Author:huchao
 * @Date: 2020-07-20 16:00:09
 * @LastEditTime: 2023-06-30 16:07:06
 * @LastEditors: huchao huchao-khd@bestpay.com.cn
 * @Description: In User Settings Edit
-->

<template>
  <div class="tableDivStyle">
    <!-- 是否需要搜索表单 -->
    <CreateForm
      v-if="searchForm"
      ref="seachRef"
      :itemList="searchForm.itemList"
      :editConfig="searchForm.config"
      :editData="searchForm.data"
    />
    <!-- 是否展示tabs组件 -->
    <Tabs
      v-if="tableConfig.tabs"
      :tableConfig="tableConfig"
      @handleSizeChange="handleSizeChange"
    />
    <!-- :height="tableConfig.maxHeight || screenHeight"    el-table中有这个属性但是值有问题暂时去掉 -->
    <el-table
      stripe
      size="small"
      ref="multipleTable"
      v-loading="tableLoading"
      element-loading-spinner="el-icon-loading"
      element-loading-text="数据加载中"
      element-loading-background="#fff"
      :data="tableData"
      :row-key="tableConfig.selection ? tableConfig['row-key'] : ''"
      :header-cell-style="
        tableConfig.headerStyle || { background: '#f5f7fa', color: '#606266' }
      "
      highlight-current-row
      :row-class-name="tableConfig.tableRowClassName"
      :show-summary="tableConfig.isTotal"
      :summary-method="tableConfig.getSummaries"
      :span-method="tableConfig.spanMethodFun"
      :default-sort="tableConfig.defaultSort"
      :tree-props="{ children: 'childList', hasChildren: 'hasChildren' }"
      @sort-change="tableConfig && tableConfig.sortChange"
      @selection-change="tableConfig.selectionChange"
      @select="tableConfig.selectChange"
      @select-all="tableConfig.selectAll"
    >
      <!-- (val)=>{ tableConfig.selectionChange(val) } -->
      <!-- @sort-change="handleSort"
          highlight-current-row
          @filter-change="filterHandler"
          @row-click="handleRowClick"-->
      <!-- 是否开启多选模式 -->
      <!-- :selectable="tableConfig.selection ? tableConfig.seleceFun : ''" v-if="tableConfig.selection"-->
      <!-- :disabled="tableConfig.selectionDisabled" -->
      <el-table-column
        v-if="tableConfig.selection"
        :selectable="tableConfig.selectable ? tableConfig.selectable : null"
        type="selection"
      />
      <template v-for="(th, key) in tableHeader">
        <el-table-column
          v-if="hideColumn(th, key)"
          :key="key"
          :prop="th.prop"
          :label="th.label"
          :fixed="th.fixed"
          :show-overflow-tooltip="th.ellipsis ? th.ellipsis : false"
          :sortable="th.sortable ? 'custom' : false"
          :filters="th.filters"
          :column-key="th.columnKey"
          :filtered-value="th.filteredValue"
          :filter-multiple="th.filterMultiple"
          :width="th.minWidth"
          :render-header="th.renderHeader"
          :align="th.center || tableConfig.center || 'center'"
        >
          <!-- :class-name="th.options ? 'options-btn' : ''" -->
          <!-- th.center ? th.center : tableConfig.center === 'center' ? 'center' : 'left' -->
          <template slot-scope="scope">
            <div class="flex align-items--center justify-content--center">
              <!-- 渲染render时 -->
              <RenderSlot
                v-if="th.render"
                :render="th.render"
                :row="scope.row"
                :val="scope.row[th.prop]"
                :index="scope.$index"
                :column="th"
              />
              <!-- 图片组件 -->
              <el-image
                v-else-if="th.type === 'image'"
                :src="scope.row[th.prop]"
                :preview-src-list="[scope.row[th.prop]]"
                style="width: 50px"
              />
              <!-- 简单的文字展示 -->
              <el-tag v-else-if="th.type === 'tag'" :type="th.tagType">{{
                scope.row[th.prop]
              }}</el-tag>
              <!-- 渲染最右边的操作的事件按钮 -->
              <RenderBtn
                v-else-if="th.options"
                :scope="scope"
                :options="th.options"
                :dialogConfig="dialogConfig"
              />
              <!-- 默认展示内容 -->
              <DefaultText
                v-else
                :row="scope.row"
                :propName="th.prop"
                :headerItem="th"
              />
              <!-- 是否需要复制功能 -->
              <CopyBtn v-if="th.copy" :row="scope.row" :propName="th.prop" />
              <!-- 需要排序功能时 -->
              <SortPopover
                v-if="th.type === 'sort'"
                :row="scope.row"
                :propName="th.prop"
                :sortEvent="th.sortEvent"
                :closeSort="th.closeSort"
                :sortMax="th.sortMax"
              />
            </div>
          </template>
          <!-- 多级表头 -->
          <el-table-column
            v-for="(td, index) in th.childListArr"
            :key="index"
            :prop="td.prop"
            :label="td.label"
            :fixed="td.fixed"
            :sortable="td.sortable ? 'custom' : false"
            :filters="td.filters"
            :column-key="td.columnKey"
            :filtered-value="td.filteredValue"
            :filter-multiple="td.filterMultiple"
            :width="td.minWidth"
            :render-header="td.renderHeader || renderFun()"
            :align="
              th.center
                ? th.center
                : tableConfig.center === 'center'
                ? 'center'
                : 'left'
            "
          />
        </el-table-column>
      </template>
      <!-- <template slot="empty">
            <div class="text-center">
              <img src="../../assets/noData.png" alt height="300" />
              <div>
                <font class="ft16r" color="#666">暂无数据</font>
              </div>
            </div>
          </template> -->
    </el-table>
    <div class="pageDiv">
      <div>
        <slot name="tool"></slot>
      </div>
      <el-pagination
        v-if="tableConfig.pagination"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableConfig.total"
        :page-size="tableConfig.pageSize"
        :current-page="tableConfig.pageNumber"
        :page-sizes="tableConfig.pageSizeArr || [10, 20, 30, 50, 100]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 弹窗表单 -->
    <DialogForm
      ref="dialogForm"
      v-if="dialogConfig"
      :dConfig="dialogConfig"
      :dForm="dialogConfig.form"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import Sortable from "sortablejs";

import Tabs from "./tabs";
import CopyBtn from "./copyBtn";
import RenderBtn from "./renderBtn";
import SortPopover from "./sortPopover";
import DefaultText from "./defaultText";

// 自定义内容的组件
const RenderSlot = {
  functional: true, // 设为函数组件
  props: {
    row: Object, // 当前row数据
    render: Function, // render渲染方法
    index: Number, // 当前index
    val: [String, Number, Array, Object, Boolean], // 当前val
    column: {
      type: Object,
      default: null,
    },
  },
  // 当前值存在时才返回render函数，否则展示‘-’
  render: (h, data) => {
    const { val, render, column } = data.props;
    // isComplexType代表是否需要渲染复杂功能内容
    if (column.isComplexType) {
      return render(h, data.props);
    }
    return val || val == 0 ? render(h, data.props) : h("span", "-");
  },
};
export default {
  name: "CreateTable",
  components: {
    Tabs,
    CopyBtn,
    RenderBtn,
    RenderSlot,
    DefaultText,
    SortPopover,
  },
  props: {
    // 搜索的form表单
    searchForm: {
      type: Object,
      default: () => null,
    },
    // 弹框配置项
    dialogConfig: {
      type: Object,
      default: () => null,
    },
    // 弹框中的form表单
    dialogForm: {
      type: Object,
      default: () => ({}),
    },
    // 表格配置项
    tableConfig: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
    // 表格数据
    tableData: {
      type: Array,
      required: true,
      default: () => [],
    },
    // 表头数据
    tableHeader: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
    // 表格配置项
    // dragConfig: {
    //   type: Object,
    //   default: function () {
    //     return {};
    //   },
    // },
  },
  data() {
    return {
      radio1: "",
      skeLoading: "init", // 状态为初始化
      fixedHeight: 210, // 设置一个默认的高度值
      screenHeight: 0, // 表单整体的高度，默认0
      sortInputVal: "", // 排序暂存的值
    };
  },
  computed: {
    // 动态计算skeleton的行数
    skeletonRows() {
      const mH = this.tableConfig.maxHeight || this.screenHeight;
      return mH ? (mH / 48) | 0 : 10;
    },
    // 判断是否展示loading
    loadingFlag() {
      return !this.tableConfig.loading && this.tableData.length < 1;
    },
    // 获取当前接口load状态
    tableLoading() {
      return this.$store.getters.tableLoading;
    },
  },
  watch: {
    // tableData: {
    //   handler(val) {
    //     // if (this.skeLoading === 'init') {
    //       this.skeletonState(val);
    //     // }
    //   },
    //   // deep: true,
    //   // immediate: true,
    // },
  },
  activated() {
    this.$nextTick(() => {
      // 解决bug: 偶尔会出现，表格错位的情况，重新计算表格高度
      if (this.$refs.multipleTable && this.$refs.multipleTable.doLayout) {
        this.$refs.multipleTable.doLayout();
      }
      this.getTableHeight();
    });
  },
  created() {
    // console.log('当前哈哈哈===》',this.tableData)
  },
  mounted() {
    this.$nextTick(() => {
      this.getTableHeight();
    });
    // 处理屏幕高度的问题，不出现滚动条
    window.onresize = this.getTableHeight;
    // 如果配置了拖拽排序，则进入
    const { dragConfig } = this.tableConfig || {};
    // 不需要自动初始化时，将noInit设置为true
    if (dragConfig && !dragConfig.noAutoInit) {
      this.initDragSort();
    }
  },
  methods: {
    /**
     * @description: 初始化拖拽排序功能
     * @param dom 手动初始化时，传入的dom节点
     */
    initDragSort(dom) {
      const el = document.querySelectorAll(
        ".el-table__body-wrapper > table > tbody"
      );
      let { changeDragData, ...config } = this.tableConfig.dragConfig || {};
      // 合并主动设置的参数和默认参数
      const dragConfig = Object.assign(
        {
          animation: 220,
          handle: this.tableConfig.handle ? `.${this.tableConfig.handle}` : "",
          ghostClass: "blue-background-class",
          onEnd: ({ oldIndex, newIndex }) => {
            // 监听拖动结束事件
            // 1、如果拖动的位置相等的话，则不需要进入方法
            if (oldIndex === newIndex) return;
            // 2、不直接更改父组件数据
            const tables = [...this.tableData];
            // 3、拿到需要移动的row，并且将从数组中移除
            console.log(oldIndex, "oldIndex", newIndex);
            const oldVal = tables.splice(oldIndex, 1)[0];
            // console.log(tables.splice(oldIndex, 1)[0].goodsNum, 'tables[0].goodsNum')
            // console.log(oldVal.goodsNum, 'oldVal')
            // 4、再将老的row替换放下的位置
            tables.splice(newIndex, 0, oldVal);
            // 5、调用父组件的回调，更改源数据
            changeDragData && changeDragData(tables, config);
          },
        },
        config
      );

      // dragConfig.
      if (dom) {
        new Sortable(dom, dragConfig);
      } else {
        new Sortable(el[0], dragConfig);
      }

      //  如果当前页面需要拖拽的表格数量超过1个，则循环初始化拖拽
      // if (el.length > 1) {
      //   for (let i = 0; i < el.length; i++) {
      //     new Sortable(el[i], dragConfig);
      //   }
      // } else {
      //   // 根据具体需求配置options配置项
      //   new Sortable(el[0], dragConfig);
      // }
    },
    sortPopClick(val) {
      // this.sortInputVal = val;
    },
    sortInputClick(row, key) {
      this.sortInputVal ? (row[key] = this.sortInputVal) : "";
    },
    /**
     * @description: 计算表格高度，动态设置
     * @author: huchao
     */

    getTableHeight() {
      const { offsetHeight = 0 } = document.querySelector(".seachStyle") || {}; // 搜索表单的节点，默认0
      const { offsetHeight: pageDivHeight } =
        document.querySelector(".pageDiv") || {}; // 搜索表单的节点，默认0
      const { pagination, tabs } = this.tableConfig;
      let paginationHeight = 0; // 分页组件的高度，默认0
      // 有分页时，将分页的高度计算进去
      if (pagination) {
        paginationHeight += 20;
      }
      // 存在tabs时
      if (tabs) {
        paginationHeight += 36;
      }
      // 当不需要底部时，减去对应的高度
      if (pageDivHeight === 0) {
        paginationHeight -= 50;
      }
      this.screenHeight =
        document.body.clientHeight -
        this.fixedHeight -
        offsetHeight -
        paginationHeight;

      if (this.screenHeight < 10) {
        this.screenHeight = 300;
      }
    },
    // 改变每页多少条的事件
    handleSizeChange(val) {
      this.tableConfig.pageNumber = 1;
      this.tableConfig.pagination = false; // 解决切换一页多少条时，页码没有初始化1的bug
      this.tableConfig.pageSize = val;
      this.tableConfig.getDataList();
      this.$nextTick(() => {
        // 重新渲染分页
        this.tableConfig.pagination = true;
      });
    },
    // 改变页码的事件
    handleCurrentChange(val) {
      this.tableConfig.pageNumber = val;
      this.tableConfig.getDataList();
    },
    // 排序事件
    handleSort(sort) {
      this.$emit("sortEvents", sort);
    },
    // 筛选事件
    // filterHandler(filters) {
    //   this.$emit('filterEvents', filters)
    // },
    // 某一行被点击
    handleRowClick(row) {
      this.$emit("row-click-events", row);
    },
    // 多选框改变的时候
    handleSelectionChange(val) {
      this.$emit("handleSelection", { val, refVal: this.$refs.multipleTable });
    },
    // 设置表格的选中
    toggleSelection(row, flag) {
      if (row) {
        this.$refs.multipleTable.toggleRowSelection(row, flag);
      }
    },
    // 取消表格的选中
    clearSelection() {
      this.$refs.multipleTable.clearSelection();
    },
    // 判断td是否显示
    hideColumn(item) {
      // 当为布尔值时，直接返回
      if (typeof item.hide === "boolean") {
        return !item.hide;
      }
      // 当为函数时，调用返回
      if (typeof item.hide === "function") {
        return !item.hide(item);
      }
      return true;
    },
    /**
     * @description: 判断按钮是否禁用
     * @param { Object } item 当前config配置的按钮信息
     * @param { Number} index 当前表格的row数据
     * @return { Boolean } return 一个布尔值
     * @author: huchao
     */
    isDisabled(item, row) {
      if (typeof item.disabled === "boolean") {
        return item.disabled;
      }
      if (typeof item.disabled === "function") {
        return item.disabled(row);
      }
      return false;
    },
    // 多选框的全选
    // selectAll(val) {
    //   this.$emit('selectAll', val)
    // }
  },
};
</script>
<style scoped>
.tableDivStyle {
  /* padding: 10px; */
  /* background-color: #fff; */
}
/* .tableStyle {
  width: 100%
} */
:deep .el-table__body .options-btn .cell {
  text-align: left !important;
}
:deep.el-table .el-table__body .cell {
  font-size: 12px !important;
}

:deep .el-form--inline .el-form-item {
  margin: 0;
}

.popClass {
  z-index: 99999;
  background: #000;
}
.pageDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
:deep .el-pagination {
  text-align: right;
}
:deep .el-image__preview {
  cursor: zoom-in !important;
}
:deep .el-pagination {
  padding: 10px 20px;
}
:deep .el-skeleton__item {
  height: 30px;
}
</style>
