var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      attrs: {
        disabled: _vm.disabledTips,
        content: _vm.row[_vm.propName],
        placement: "top",
      },
    },
    [
      _c("span", { staticClass: "single-row-text" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.white.includes(_vm.row[_vm.propName]) &&
                _vm.row[_vm.propName] !== 0
                ? _vm.headerItem.defaultText || "-"
                : _vm.row[_vm.propName]
            ) +
            "\n  "
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }