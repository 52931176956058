<template>
  <el-button-group>
    <el-button 
    :key="val"
    class="btnTabs"
    @click="btnClick(val)"
    :disabled="tableLoading"
    v-for="(key, val) in tableConfig.tabs.enum"
    :type="currentVal === val ? 'primary' : ''"
    >{{ key }}</el-button>
  </el-button-group>
</template>

<script>

export default {
  name: 'copyBtn',
  props: {
    tableConfig: {
      type: Object,
      default: () => ({
        tabs: {
          bindValue: '',
          enum: {}
        }
      })
    }
  },
  data() {
    return {
      currentVal: '' // 当前选中的值
    }
  },
  computed: {
    // 获取请求接口时的load状态，在此组件是为了防止第一次请求未完成，点击了第二次的场景
    tableLoading(){
        return this.$store.getters.tableLoading
    }
  },
  created() {
    const { tabs, searchForm } = this.tableConfig;
    if (!searchForm) {
      // 因为tabs的val值是接口的查询参数，所以需要将searchForm传入
      window.alert('开发提醒，使用了【tabs组件】请在table的config中，将搜索表单【searchForm】字段属性传入');
    }
    // 设置默认值
    this.currentVal = searchForm.data[tabs.bindValue];
  },
  methods: {
    /**
     * @description: 点击按钮时触发
     * @param {*} val 当前点击的val值
     */    
    btnClick(val){
      this.currentVal = val;
      const { tabs, pageSize, pagination, getDataList } = this.tableConfig;
      this.tableConfig.searchForm.data[tabs.bindValue] = val;
      pagination ? this.$emit('handleSizeChange', pageSize) : getDataList();
    },
  }
}
</script>

<style scoped>
.btnTabs {
  border: none;
  border-radius: 0 !important;
}
.btnTabs:first-child {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.btnTabs:last-child {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
</style>
