<template>
  <el-popover
    v-model="showPopover"
    ref="popover"
    placement="right"
    width="300"
    trigger="click"
  >
    <div>
      <p>修改排序</p>
      <div class="sortDiv">
        <el-input
          size="small"
          type="number"
          v-model="sortInputVal"
          placeholder="请输入大于0的正整数"
        />
        <el-button
          size="mini"
          class="sortDiv-btn"
          :disabled="!sortInputVal"
          @click="sortInputClick"
          >确认</el-button
        >
      </div>
      <!-- <p class="sortDiv-p">注：请输入正整数</p> -->
    </div>
    <el-button
      v-show="!closeSort(row)"
      slot="reference"
      type="text"
      size="mini"
      icon="el-icon-edit-outline"
      title="修改排序"
    >
    </el-button>
  </el-popover>
</template>

<script>
import { Message } from "element-ui";
import { isInteger } from "../../utils/validate";
export default {
  name: "sortPop",
  props: {
    sortEvent: Function,
    closeSort: {
      type: Function,
      default: () => false,
    }, // 用来判断是否需要展示排序功能
    sortMax: {
      type: Number,
      default: () => 99999,
    }, // 排序最大值
    row: {
      type: Object,
      default: () => {},
    },
    propName: {
      type: String,
      default: () => "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    column: {},
  },
  data() {
    return {
      showPopover: false,
      sortInputVal: "", // 排序暂存的值
    };
  },
  created() {},
  methods: {
    /**
     * @description: 排序确认触发事件
     * @author: huchao
     */
    sortInputClick() {
      if (this.judgeValue(this.sortInputVal)) {
        this.sortEvent(this.row, this.sortInputVal, this.callback);
      }
    },
    /**
     * @description: input更改事件
     * @author: huchao
     */
    judgeValue(val) {
      if (val <= 0 || !isInteger(Number(val))) {
        Message.warning("请输入大于0的整数");
        return false;
      } else if (Number(val) > this.sortMax) {
        Message.warning(`请输入小于${this.sortMax}的整数`);
        return false;
      }
      return true;
    },
    /**
     * @description: 关闭Popover，并且还原sortInputVal
     * @author: huchao
     */
    callback() {
      this.row[this.propName] = this.sortInputVal;
      this.sortInputVal = "";
      this.showPopover = false;
    },
  },
};
</script>

<style scoped>
.sortDiv {
  display: flex;
  margin-top: 10px;
}
.sortDiv-p {
  font-size: 12px;
}
.sortDiv-btn {
  margin-left: 10px;
}
</style>
