<template>
  <el-tooltip :disabled="disabledTips" :content="row[propName]" placement="top">
    <!-- defaultText -->
    <span class="single-row-text">
      {{
        white.includes(row[propName]) && row[propName] !== 0
          ? headerItem.defaultText || "-"
          : row[propName]
      }}
    </span>
  </el-tooltip>
</template>

<script>
export default {
  name: "copyBtn",
  props: {
    // 当前行数据
    row: {
      type: Object,
      default: () => ({}),
    },
    // 当前propName
    propName: {
      type: String,
      default: () => "",
    },
    // 当前table头某一项的配置
    headerItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      white: [null, undefined, ""],
    };
  },
  computed: {
    // 判断是否展示提示框
    disabledTips(e) {
      const val = this.row[this.propName];
      if (val && typeof val === "string") {
        return val.length < 26;
      }
      return true;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
/* :deep .el-tooltip__popper {
  padding: 10px 60px !important;
} */
</style>
