// import Vue from 'vue'

import "normalize.css/normalize.css"; // A modern alternative to CSS resets
// window.qs = {}
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "#/iconfont/iconfont.css";
import CreateForm from "./components/createForm/index.vue";
import CreateTable from "./components/createTable/index.vue";
import DialogForm from "./components/DialogForm/index.vue";
// import zhLocale from 'element-ui/lib/locale/lang/en'
// import $ from 'jquery'
// import '#/styles/index.scss' // global css
import "#/permission"; // permission control
import App from "./App";
import router from "./router";
import store from "./store";
import http from "./utils/http";
import VueI18n from "vue-i18n";
import enLocale from "element-ui/lib/locale/lang/en"; //element-ui ui英文
import zhLocale from "element-ui/lib/locale/lang/zh-CN"; //element-ui ui中文
import eLocale from "element-ui/lib/locale/lang/ru-RU";
import en from "@/lang/en"; //  英文
import zh from "@/lang/zh"; // 中文
import sh from "@/lang/sh"; // 中文
import e from "@/lang/e";
import _en from "#/lang/en"; //  英文
import _zh from "#/lang/zh"; // 中文
import _e from "#/lang/e";

import "../static/public/iconfont.css";


import "#/icons"; // icon
import VueDND from "awe-dnd";
import baseURL from "@/utils/host";
import { initImpl } from "qj-proxyapi";
import VueClipBoard from "vue-clipboard2";

import xss from "xss";
Vue.prototype.baseUrl = baseURL;
Vue.prototype.$xss = xss;
Vue.prototype.$http = http;

initImpl();
// import axios from 'axios';
// Vue.use(ElementUI, { locale })
Vue.use(VueDND);
Vue.use(VueClipBoard);
// Vue.use(ElementUI, { zhLocale })
Vue.config.productionTip = false;
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: localStorage.getItem("inter") || "zh", // set locale
  messages: {
    en: {
      ...en,
      ..._en,
      ...enLocale,
    },
    zh: {
      ...zh,
      ..._zh,
      ...zhLocale,
    },
    sh: {
      ...sh,
      ..._zh,
      ...zhLocale,
    },
    e: {
      ...e,
      ..._e,
      ...eLocale, // 或者用 Object.assign({ message: '你好' }, zhLocale)
    },
  },
});
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
});
Vue.component("CreateForm", CreateForm);
Vue.component("CreateTable", CreateTable);
Vue.component("DialogForm", DialogForm);

Vue.prototype.$th = (key1, key) => {
  return i18n.t(key1 ? key1 + key : key);
};
//
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// Vue.prototype.$axios = axios;
// Vue.http.options.emulateJSON = true;
// Vue.http.options.headers = {
//     'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
// };
function init_vue() {
  new Vue({
    el: "#app",
    router,
    store,
    template: "<App/>",
    components: { App },
  });
}
init_vue();
