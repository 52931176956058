
import request from '@/utils/request'

let menuListApi = {
    queryTginfoMenuTree: (data) => { //树形列表
        return request({
            url: '/web/cms/tginfoMenu/queryTginfoMenuTree.json',
            method: 'get',
            params: data
        })
    },
    queryTginfoMenuPage: (data) => { //列表
        return request({
            url: '/web/cms/tginfoMenu/queryTginfoMenuPage.json',
            method: 'get',
            params: data
        })
    },
    
    queryTginfoPage: (data) => { //获取站点下拉
        return request({
            url: '/web/cms/tginfo/queryTginfoPage.json',
            method: 'get',
            params: data
        })
    },
    queryPfsModelPage: (data) => { //模板查询（主/明细）
        return request({
            url: '/web/pfs/pfsmodel/queryPfsModelPage.json',
            method: 'post',
            data
        })
    },
    deleteTginfoMenu: (data) => { //删除栏目）
        return request({
            url: '/web/cms/tginfoMenu/deleteTginfoMenu.json',
            method: 'post',
            data
        })
    },
}

export default menuListApi;