
const HostConfig = {
  'merchant-h5.test.bestpay.net': 'mallmerchant.test.bestpay.net',
  'merchant-h5-pre.bestpay.com.cn': 'mallmerchant-pre.bestpay.com.cn',
  'merchant-h5.bestpay.com.cn': 'mallmerchant.bestpay.com.cn',
  'operator-h5.test.bestpay.net': 'mallbus.test.bestpay.net',
  'operator-h5-pre.bestpay.com.cn': 'mallbus-pre.bestpay.com.cn',
  'operator-h5.bestpay.com.cn': 'mallbus.bestpay.com.cn',
}

const baseURL = () => {
  const { protocol, host } = window.location;
  return `${protocol}//${HostConfig[host] || host}`
}

export default baseURL();
