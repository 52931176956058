// 登录路由添加 begin
import { getProappinfo, getTopPerMenuListFPc } from "#/api/login";
// 登录路由添加 end

import Layout from "#/views/layout/Layout";

const _import = require("./_import_" + process.env.NODE_ENV);

export const developmentRouterMap = [
  {
    path: "/",
    component: Layout,
    name: "merchant-pc-huaxi",
    children: [
      // {
      //   path: "orderListAll",
      //   component: _import("order/listAll/listAllZq"),
      // },
      {
        path: "orderListAll",
        component: _import("orderList/index"),
      },
      {
        path: "goodsRelease",
        component: _import("goodsCenter/goodsRelease/goodsRelease/index"),
      },
      {
        path: "goodsDetails",
        component: _import(
          "goodsCenter/goodsManage/goodsList/goodsDetails/index"
        ),
      },
      {
        path: "goodsListEdit",
        component: _import("goodsCenter/goodsManage/goodsList/listEdit"),
      },
      {
        path: "settlementCenter",
        component: _import("settlementCenter/index"),
      },
      {
        path: "orderGoodList",
        component: _import("order/listAll/orderGoodList"),
      },
      {
        path: "goodsList",
        component: _import("goodsCenter/goodsManage/goodsList/index"),
      },
      {
        path: "goodsListDetails",
        component: _import("goodsCenter/goodsManage/goodsList/listDetails"),
      },
      {
        path: "goodsRelease",
        component: _import("goodsCenter/goodsRelease/goodsRelease/index"),
      },
      {
        path: "ReturnsAddress",
        component: _import("orderCenter/aftersalesManage/returnsAddress/index"),
      },
      {
        path: "returnsList",
        component: _import("orderCenter/aftersalesManage/returnsList/index"),
      },
      // 发布商品
      {
        path: "spRelease",
        component: _import("userGoods/index"),
      },
      //满减活动列表
      {
        path: "priceDiscountList",
        component: _import(
          "marketingCenter/mallMarketingManage/priceDiscountList/index"
        ),
      },
      //满减活动列表--新增
      {
        path: "editPriceDiscountList",
        component: _import(
          "marketingCenter/mallMarketingManage/priceDiscountList/components/editPriceDiscountList"
        ),
      },
      //满赠活动列表
      {
        path: "giveawayList",
        component: _import(
          "marketingCenter/mallMarketingManage/giveawayList/index"
        ),
      },
      //满赠活动列表--新增
      {
        path: "editGiveawayList",
        component: _import(
          "marketingCenter/mallMarketingManage/giveawayList/components/editGiveawayList"
        ),
      },
      //满折活动列表
      {
        path: "discountList",
        component: _import(
          "marketingCenter/mallMarketingManage/discountList/index"
        ),
      },
      //满折活动列表--编辑
      {
        path: "editDiscountList",
        component: _import(
          "marketingCenter/mallMarketingManage/discountList/components/editDiscountList"
        ),
      },
      //满折活动列表--新增
      {
        path: "addDiscountList",
        component: _import(
          "marketingCenter/mallMarketingManage/discountList/components/addDiscountList"
        ),
      },
      //优惠劵活动列表
      {
        path: "voucherList",
        component: _import(
          "marketingCenter/mallMarketingManage/voucherList/index"
        ),
      },
      //优惠劵活动列表--新增
      {
        path: "addVoucherList",
        component: _import(
          "marketingCenter/mallMarketingManage/voucherList/components/addVoucherList"
        ),
      },
      //编辑-代金券列表
      {
        path: "editVoucherList",
        component: _import(
          "marketingCenter/mallMarketingManage/voucherList/components/editVoucherList"
        ),
      },
      //个人信息
      {
        path: "userinfo",
        component: _import("userinfo/index"),
      },
      //管理员列表
      {
        path: "administratorlist",
        component: _import("up/operatorlist/index"),
      },
      //订单管理
      {
        path: "orderList",
        component: _import("orderList/index"),
      },
      //店铺装修
      {
        path: "shopDecorate",
        component: _import("zhuanxiu/shop_decorate"),
      },
      //佣金管理
      {
        path: "commission",
        component: _import("commission/proportion"),
      },
      //首页
      {
        path: "enterprise",
        component: _import("dashboard/enterprise"),
        name: "首页",
      },
      {
        path: "goodsBatchUpload",
        component: _import("goodsCenter/goodsRelease/goodsBatchUpload/index"),
      },
      {
        path: "picturesBatchUpload",
        component: _import(
          "goodsCenter/goodsRelease/picturesBatchUpload/index"
        ),
      },
      {
        path: "picturesDetailsBatchUpload",
        component: _import(
          "goodsCenter/goodsRelease/picturesDetailsBatchUpload/index"
        ),
      },
      {
        path: "marketingAudit",
        component: _import("marketingCenter/marketingAudit/index"),
      },
      {
        path: "freight",
        component: _import("freight/freighttem1/index"),
      },
      //异步下载
      {
        path: "importdata",
        component: _import("systemManager/importDataPg"),
      },
      {
        path: "giveawayList",
        component: _import(
          "marketingCenter/mallMarketingManage/giveawayList/index"
        ),
      },
      {
        path: "commission",
        name: '佣金管理"',
        component: _import("commission/proportion"),
      },
      {
        path: "userinfo",
        name: '个人信息"',
        component: _import("userinfo/index"),
      },
      {
        path: "returnsList",
        name: '退单列表"',
        component: _import("orderCenter/aftersalesManage/returnsList/index"),
      },
      {
        path: "/fgPassword",
        name: "fgPassword",
        component: _import("loginPc/forgetPwdPc/indexsupplier"),
      },
      {
        path: "reconciliationReport",
        name: "对账报表",
        component: _import("reportFormsCenter/reconciliationReport/index"),
      },
    ],
  },
];
export const productionRouterMap = [
  {
    path: "/login",
    name: "登录",
    component: _import("login/loginWhite"),
    // 登录路由添加 begin
    beforeEnter: (to, from, next) => {
      console.log("是的我在这里执行了！");
      let sJson = JSON.parse(window.sessionStorage.getItem("proappEnvLayout"));
      if (sJson) {
        next();
      } else {
        getProappinfo().then((data) => {
          if (data) {
            window.sessionStorage.setItem(
              "proappEnvLayout",
              JSON.stringify(data)
            );
            window.sessionStorage.getItem("menuList") ||
              getTopPerMenuListFPc().then((res) => {
                window.sessionStorage.setItem("menuList", JSON.stringify(res));
              });
            getLink(data);

            next();
          }
        });
      }
    },
    // 登录路由添加 end
  },
  {
    path: "/fgPassword",
    name: "fgPassword",
    component: _import("loginPc/forgetPwdPc/indexsupplier"),
  },
];
export default new VueRouter({
  scrollBehavior: () => ({
    y: 0,
  }),
  routes:
    process.env.NODE_ENV == "development"
      ? developmentRouterMap
      : productionRouterMap,
});

// 登录路由添加 begin
function getLink(data) {
  var url =
    "https://qjstatic.oss-cn-shanghai.aliyuncs.com/theme/index.min.409eff.css";
  if (data && data.proappEnvTheme) {
    url =
      "https://qjstatic.oss-cn-shanghai.aliyuncs.com/theme/index.min." +
      data.proappEnvTheme +
      ".css";
  }
  addCssByLink(url);
}

function addCssByLink(url) {
  var doc = document;
  var link = doc.createElement("link");
  link.setAttribute("rel", "stylesheet");
  link.setAttribute("type", "text/css");
  link.setAttribute("href", url);

  var heads = doc.getElementsByTagName("head");
  if (heads.length) heads[0].appendChild(link);
  else doc.documentElement.appendChild(link);
}
// 登录路由添加 end
