import Cookies from "js-cookie";

const TokenKey = "Admin-Token";

export function getToken(TokenKey) {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function getLocalStorage(pcode) {
  if (localStorage.getItem(pcode)) {
    return localStorage.getItem(pcode);
  }
}
export function getLocalStoragePcode() {
  return localStorage.pcode;
}
export function getSessionStorage(tcode) {
  const value = sessionStorage.getItem(tcode);
  if (!value) {
    return null;
  }
  return JSON.parse(value);
}
export function setLocalStoragePcode(pcode) {
  return (localStorage.pcode = pcode);
}

export function getLocalStorageTcode() {
  return localStorage.tcode;
}

export function setLocalStorageTcode(tcode) {
  return (localStorage.tcode = tcode);
}
/**
 * 获取商家端和运营端的标示
 */
export function getProappType() {
  const { proappCode } = getSessionStorage("proappEnvLayout") || {};
  // 运营端
  if (proappCode === "022") {
    return "PLATFORM";
  }
  // 商家端
  if (proappCode === "002") {
    return "BUSINESS";
  }
  return "";
}
