import { twoDecimal, isInteger } from "./validate";

/**
 *
 * @param {String} key // 对应要解析的key 类似于 obj.a.c 或是 key
 * @returns
 */
export function edit_data_path(key, Obj) {
  console.log("传入的key", key);
  const keys = key.split(".");
  let value = Obj;
  let obj = Obj;
  let key_value = key;
  try {
    if (keys.length === 1) {
      return {
        value: Obj[key],
        obj,
        key: key_value,
      };
    }
    for (const key of keys) {
      obj = value;
      value = value[key];
      key_value = key;
      let next = (value !== null) & (typeof value === "object");
      if (!next) {
        break;
      }
    }
    if (!(key_value in obj)) {
      console.error("请注意在 Obj 上面并没有配置默认key", key_value);
    }
  } catch (error) {
    console.log("功能校验异常", error);
  }
  return {
    value,
    obj,
    key: key_value,
  };
}

/**
 * @description: 表单中验证百分比，并且保留两位小数
 * @param {*} rule  当前需要验证的规则
 * @param {*} value 当前输入的值
 * @param {*} callback 回调函数
 * @return {*} callback
 * @author: huchao
 */
export const percentageRule = (rule, value, callback) => {
  // 判断0-100
  if (isNaN(value) || value < 0 || value > 100) {
    return callback(new Error("请输入0-100的值"));
  }
  // 判断是否保留两位小数
  else if (twoDecimal(value)) {
    return callback(new Error("请输入保留两位小数的值"));
  } else {
    callback();
  }
};

export const freightRule = (rule, value, callback) => {
  // 判断0-10000
  if (isNaN(value) || value < 0 || value > 10000) {
    return callback(new Error("金额只可输入0-10000元"));
  }
  // 判断是否保留两位小数
  else if (twoDecimal(value)) {
    return callback(new Error("请输入保留两位小数的值"));
  } else {
    callback();
  }
};

/**
 * @description: https/http 链接校验
 * @author: huchao
 */
export const ruleLink = (_rule, value, callback) => {
  var reg = /(http|https):\/\/([\w.]+\/?)\S*/;
  if (value && !reg.test(value)) {
    callback(new Error("请输入正确的链接格式"));
  } else {
    callback();
  }
};

/**
 * @description: 手机号校验
 * @author: huchao
 */
export const rulePhone = (_rule, value, callback) => {
  if (value && !/^1[3456789]\d{9}$/.test(value)) {
    callback(new Error("请输入正确的手机号"));
  } else {
    callback();
  }
};

/**
 * @description: 手机号校验
 * @author: huchao
 */
export const ruleInteger = (_rule, value, callback) => {
  if (value <= 0) {
    callback(new Error("请输入大于0的正整数"));
  } else if (value && !isInteger(value)) {
    callback(new Error("请输入正确的正整数"));
  } else {
    callback();
  }
};
