import request from '@/utils/request'

let picListApi = {
queryAdvertisePage: (data) => {
return request({//广告位列表查询
url: '/web/cms/advertise/queryAdvertisePage.json',
method: 'get',
params: data
})
},
getTginfoMenuPage: (data) => {
return request({ //栏目列表
url: '/web/cms/tginfoMenu/queryTginfoMenuPage.json',
method: 'get',
params: data
})
},
}

export default picListApi;