<!--
 * @Author:huchao
 * @Date: 2020-07-20 16:00:09
 * @LastEditTime: 2023-06-20 09:53:28
 * @LastEditors: liudong 58164700+ruyanwangshi@users.noreply.github.com
 * @Description: In User Settings Edit
-->
<template>
  <el-form
    ref="editForm"
    class="formStyle"
    :class="editConfig.isShowSearchBtn ? 'seachStyle' : ''"
    :size="editConfig.size || 'small'"
    :inline="editConfig.inline"
    :label-position="editConfig.labelPosition || 'right'"
    :label-width="editConfig.labelWidth"
    :model="editData"
    :rules="editRulesObj"
    :disabled="editDisabled"
  >
    <template v-for="(item, itemIndex) in itemList">
      <!-- v-for="(item, itemIndex) in itemList" -->
      <!-- <el-form-item
        v-if="formItemShow(editData, item)"
        :label="item.label + '：'"
        :prop="item.prop"
        :key="item.label"
      >
      <span>{{editData[item.prop]}}</span>
      </el-form-item> -->
      <el-form-item
        v-if="formItemShow(editData, item)"
        :label="item.label + '：'"
        :prop="item.prop"
        :key="item.label"
        :label-width="item.labelWidth"
      >
        <!-- 输入框 -->
        <el-input
          v-if="item.type === 'input'"
          :type="item.type"
          clearable
          :maxlength="item.maxlength ? item.maxlength : 999"
          :value="getValue(editData, item)"
          :placeholder="
            dgConfigType === 'see'
              ? ''
              : item.placeholder
              ? item.placeholder
              : '请输入' + item.label
          "
          @change="setValue($event, editData, item, 'change')"
          @input="setValue($event, editData, item, 'input')"
          :disabled="item.disabled && item.disabled(editData)"
          :style="{ width: item.width }"
        />
        <!-- item.change && item.change(editData[item.prop], editData) -->
        <!-- item.input && item.input(editData[item.prop], editData) -->
        <!-- 数字框 -->
        <el-input
          v-if="item.type === 'number'"
          type="age"
          clearable
          :value="getValue(editData, item)"
          :placeholder="dgConfigType === 'see' ? '' : '请输入' + item.label"
          @input="setValue($event, editData, item, 'input')"
          @change="setValue($event, editData, item, 'change')"
          :disabled="item.disabled && item.disabled(editData)"
          :style="{ width: item.width }"
        />
        <!-- 文本域 -->
        <el-input
          v-if="item.type === 'textarea'"
          type="textarea"
          clearable
          :value="getValue(editData, item)"
          :placeholder="dgConfigType === 'see' ? '' : '请输入' + item.label"
          @input="setValue($event, editData, item, 'input')"
          @change="setValue($event, editData, item, 'change')"
          :disabled="item.disabled && item.disabled(editData)"
          :maxlength="item.maxlength"
          :show-word-limit="item.maxlength ? true : false"
          :rows="4"
          :style="{ width: item.width }"
        />
        <!-- 下拉框 -->
        <el-select
          v-if="item.type === 'select'"
          :clearable="!item.clearable"
          :value="getValue(editData, item)"
          :placeholder="dgConfigType === 'see' ? '' : '请选择' + item.label"
          @change="setValue($event, editData, item, 'change')"
          :disabled="item.disabled && item.disabled(editData)"
        >
          <!-- 渲染数组 item.options-->
          <template v-if="Array.isArray(selectOption(editData, item))">
            <el-option
              v-for="(op, index) in selectOption(editData, item)"
              :label="op[item.valKey ? item.valKey.label : 'label']"
              :value="op[item.valKey ? item.valKey.value : 'value']"
              :key="op.value + '' + index"
            ></el-option>
          </template>
          <!-- 渲染对象-->
          <template v-else>
            <el-option
              v-for="(val, key) in selectOption(editData, item)"
              :label="val"
              :value="strChangeNum(key, editData[item.prop])"
              :key="key"
            ></el-option>
          </template>
        </el-select>
        <!-- 单选 -->
        <el-radio-group
          v-if="item.type === 'radio'"
          :value="getValue(editData, item)"
          @input="setValue($event, editData, item, 'input')"
          :disabled="item.disabled && item.disabled(editData)"
        >
          <el-radio
            v-for="(ra, index) in item.radios"
            :label="ra.value"
            :key="ra.value + '' + index"
            >{{ ra.label }}</el-radio
          >
        </el-radio-group>
        <!-- 单选按钮 -->
        <el-radio-group
          v-if="item.type === 'radioButton'"
          :value="getValue(editData, item)"
          @input="setValue($event, editData, item, 'input')"
          :disabled="item.disabled && item.disabled(editData)"
        >
          <el-radio-button
            v-for="(ra, index) in item.radios"
            :label="ra.value"
            :key="ra.value + '' + index"
            >{{ ra.label }}</el-radio-button
          >
        </el-radio-group>
        <!-- 复选框 -->
        <el-checkbox-group
          v-if="item.type === 'checkbox'"
          :value="getValue(editData, item)"
          @input="setValue($event, editData, item, 'input')"
          @change="setValue($event, editData, item, 'change')"
          :disabled="item.disabled && item.disabled(editData)"
        >
          <el-checkbox
            v-for="ch in item.checkboxs"
            :label="ch.value"
            :key="ch.value"
            >{{ ch.label }} {{ ch.value }}</el-checkbox
          >
        </el-checkbox-group>
        <!-- 日期 -->
        <el-date-picker
          v-if="item.type === 'date'"
          :value="getValue(editData, item)"
          :format="item.format || ''"
          :value-format="
            item.valueFormat ||
            (item.dateType === 'daterange'
              ? 'yyyy-MM-dd'
              : 'yyyy-MM-dd HH:mm:ss')
          "
          :type="item.dateType || 'date'"
          @input="setValue($event, editData, item, 'input')"
          @change="setValue($event, editData, item, 'change')"
          :disabled="item.disabled && item.disabled(editData)"
          :placeholder="dgConfigType === 'see' ? '' : '请选择' + item.label"
          :start-placeholder="item.dateType ? '开始日期' : ''"
          :end-placeholder="item.dateType ? '结束日期' : ''"
          :picker-options="getPickerOptions(editData, item)"
        />
        <!-- @change="setValue($event, editData, item, 'change')" -->
        <!-- setValue($event, editData, item, 'input') -->
        <!-- :picker-options="item.pickerOptions ? pickerOptions: {}" -->
        <!-- 日期时间 -->
        <el-date-picker
          v-if="item.type === 'dateTime'"
          :type="item.dateType || 'datetime'"
          :value="getValue(editData, item)"
          :format="item.format || ''"
          :value-format="item.valueFormat || 'yyyy-MM-dd hh:mm:ss'"
          @input="setValue($event, editData, item, 'input')"
          @change="setValue($event, editData, item, 'change')"
          :disabled="item.disabled && item.disabled(editData[item.prop])"
          :picker-options="getPickerOptions(editData, item)"
          :placeholder="dgConfigType === 'see' ? '' : '请选择' + item.label"
        />
        <!-- 时间 -->
        <el-time-select
          v-if="item.type === 'timeSelect'"
          :value="getValue(editData, item)"
          :value-format="item.format || 'hh:mm'"
          :picker-options="{
            start: '00:00',
            step: '00:05',
            end: '24:00',
          }"
          @input="setValue($event, editData, item, 'input')"
          @change="setValue($event, editData, item, 'change')"
          :disabled="item.disabled && item.disabled(editData[item.prop])"
          :placeholder="dgConfigType === 'see' ? '' : '请选择' + item.label"
        />
        <!-- 上传 item.action || -->
        <div v-if="item.type === 'upload'" class="flex flex-item-l-between">
          <FileUpload
            :ids="item.prop"
            @uploadSuccess="uploadSuccess"
            @removeImg="removeImg"
            :multiple="item.limit > 1"
            :limit="item.limit || 1"
            :fileSize="fileSizePicture"
            :fileType="
              item.fileType === 'file' ? fileTypeFile : fileTypePicture
            "
            :btnText="btnTextPicture"
            :listType="listTypePicture"
            :url="item.updateUrlName"
            :loadingText="loadingTextPicture"
            :fileUrl="editData[item.prop] ? editData[item.prop] : ''"
            :dgConfigType="dgConfigType"
            :fileListObj="
              editData[item.prop]
                ? [{ name: `${item.prop}`, url: `${editData[item.prop]}` }]
                : []
            "
          />
        </div>
        <!-- 滑块 -->
        <!-- <el-slider v-if="item.type==='Slider'" :value="getValue(editData, item)"></el-slider> -->
        <!-- 开关 -->
        <el-switch
          v-if="item.type === 'switch'"
          :value="getValue(editData, item)"
          :active-value="item.activeValue || true"
          :inactive-value="item.inactiveValue || false"
          @change="setValue($event, editData, item, 'change')"
          :disabled="item.disabled && item.disabled(editData)"
        />
        <!-- 图片image -->
        <template v-if="item.type === 'image'">
          <el-image
            v-if="formItemShow(editData, item)"
            :src="getValue(editData, item)"
            alt=""
          />
        </template>
        <!-- 范围input -->
        <template v-if="item.type === 'rangeInput'">
          <div>
            <el-input
              :type="item.type"
              clearable
              class="rangeInput"
              :maxlength="item.maxlength ? item.maxlength : 999"
              :value="getValue(editData, item, item.propList[0])"
              :placeholder="
                dgConfigType === 'see'
                  ? ''
                  : item.placeholder
                  ? item.placeholder
                  : '请输入' + item.label
              "
              @change="
                setValue($event, editData, item, 'change', item.propList[0])
              "
              @input="
                setValue($event, editData, item, 'input', item.propList[0])
              "
              :disabled="item.disabled && item.disabled(editData)"
              :style="{ width: item.width }"
            />
            <span class="rangeSpan"> ~ </span>
            <el-input
              :type="item.type"
              clearable
              class="rangeInput"
              :maxlength="item.maxlength ? item.maxlength : 999"
              :value="getValue(editData, item, item.propList[1])"
              :placeholder="
                dgConfigType === 'see'
                  ? ''
                  : item.placeholder
                  ? item.placeholder
                  : '请输入' + item.label
              "
              @change="
                setValue($event, editData, item, 'change', item.propList[1])
              "
              @input="
                setValue($event, editData, item, 'input', item.propList[1])
              "
              :disabled="item.disabled && item.disabled(editData)"
              :style="{ width: item.width }"
            />
          </div>
        </template>
        <!-- 自定义渲染 -->
        <template v-if="item.type === 'slot'">
          <RenderSlot
            v-if="item.render"
            :render="item.render"
            :editData="editData"
            :editDisabled="editDisabled"
            :val="editData[item.prop]"
            :keys="item.prop"
          />
        </template>
      </el-form-item>
    </template>
    <el-form-item v-if="editConfig.isShowSearchBtn">
      <el-button type="primary" @click="editConfig.seachFormFun"
        >搜索</el-button
      >
      <el-button @click="resetForm">重置</el-button>
    </el-form-item>
    <!-- 显示表单的操作按钮 && !editConfig.isShowSearchBtn -->
    <!-- isShowOperation: 是否展示操作按钮，默认为true -->
    <el-form-item v-else-if="editConfig.isShowOperation && !editDisabled">
      <el-button type="primary" @click="submitForm" :loading="btnLoading">{{
        btnLoading ? "加载中..." : formButtonTextHandle("confirm")
      }}</el-button>
      <el-button @click="editConfig.dialogVisible" :disabled="btnLoading">{{
        formButtonTextHandle("cancel")
      }}</el-button>
    </el-form-item>
    <!-- isNested: 当前表单是否嵌套在表单里面 -->
    <el-form-item v-else-if="!editConfig.isNested && editDisabled">
      <el-button :disabled="false" @click="editConfig.dialogVisible">{{
        formButtonTextHandle("cancel")
      }}</el-button>
    </el-form-item>

    <!-- 
        1、扩展的tools按钮功能 
        2、toolsBtnWrap代表是否需要换行
      -->
    <el-form-item
      v-if="editConfig.toolsBtn"
      :class="editConfig.toolsBtnWrap ? 'toolsBtnWrap' : ''"
    >
      <template v-for="item in editConfig.toolsBtn">
        <!-- 渲染自定义组件 -->
        <RenderSlot
          v-if="item.render"
          :render="item.render"
          :keys="item.code"
        />
        <!-- 渲染常规的button -->
        <el-button
          :plain="item.plain || false"
          :style="item.style"
          v-else
          v-show="btnShowFlag(editData, item)"
          :type="item.type || 'primary'"
          :keys="item.code"
          @click="item.method"
          >{{ item.label }}</el-button
        >
      </template>
    </el-form-item>
  </el-form>
</template>

<script>
import FileUpload from "@/components/fileUpload";
import {
  rulePhone,
  ruleLink,
  ruleInteger,
  percentageRule,
} from "../../utils/validateRule";

// 自定义内容的组件
const RenderSlot = {
  functional: true,
  props: {
    render: Function,
    editData: Object, // 当前表单数据data
    editDisabled: Boolean, // 是否为查看
    val: [String, Number, Array, Object, Boolean], // 当前val
    keys: [String, Number], // 当前需要被渲染的表单行，例如渲染城市组件，
  },
  render: (h, d) => {
    const renderFun = d.props.render(h, { props: d.props });
    return renderFun;
  },
};

export default {
  name: "createForm",
  components: {
    FileUpload,
    RenderSlot,
  },
  props: {
    itemList: {
      type: Array,
      required: true,
      default: () => [],
    },
    editDisabled: {
      type: Boolean,
      default: () => false,
    },
    editConfig: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    editData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    editRules: {
      type: Object,
      default: () => ({}),
    },
    seachForm: {
      type: Function,
    },
    // 新增的按钮，是否需要换行
    toolsBtnWrap: {
      type: Boolean,
    },
    // 扩展的btn工具
    toolsBtn: {
      type: Array,
    },
    dgConfigType: {
      type: String,
    },
  },
  data() {
    return {
      upLoadUrl: "234", // process.env.UPLOAD_FILE_URL,
      editImgFlag: true,
      editRulesObj: {},
      fileSizePicture: [5, "M"],
      btnTextPicture: ["选取图片", "上传"],
      fileTypePicture: ["jpg", "png", "jpeg", "gif"],
      fileTypeFile: ["doc", "docx", "xls", "xlsx"],
      listTypePicture: "picture-card",
      loadingTextPicture: "上传图片中...",
      pickerOptions: {
        shortcuts: [
          {
            text: "全部",
            onClick(picker) {
              picker.$emit("pick", ["", ""]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  computed: {
    // 表单提交按钮loading
    btnLoading() {
      return this.$store.getters.btnLoading;
    },
  },
  watch: {
    editData(newVal, old) {
      // console.log('newVal, old', newVal, old);
      // this.$nextTick(() => { this.$refs.editForm.clearValidate(); })
    },
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.editForm && this.$refs.editForm.clearValidate();
    });
  },
  created() {
    this.autoRules();
    // this.$nextTick(() => { this.$refs.editForm.clearValidate(); })
  },
  mounted() {},
  methods: {
    getPickerOptions(editData, item) {
      if (typeof item.pickerOptions === "function") {
        return item.pickerOptions(editData, item) || {};
      }
      return item.pickerOptions || {};
    },
    /**
     * @description: 获取深层对象的val值
     * @param {*} editData 当前表单的form
     * @param {*} item 当前渲染的配置项
     * @param {*} custom 当使用 propList 范围金额，自定义组件时，拿到另一个的prop
     */
    getValue(editData, item, custom) {
      try {
        const { prop, label } = item;
        if (!prop) {
          const strErr = `表单配置项中【${label}】字段名，未填写Prop值，请检查！`;
          this.errorTip(label, strErr);
        }
        // 1、切割testObj.a.b成['testObj', 'a', 'b']
        const props = prop.split(".");
        const returnVal = editData[custom || prop];
        // 2、当不是testObj.a.b的情况下，则直接返回值
        if (props.length === 1) {
          // 手动给form表单中，添加缺少的字段值
          if (!(prop in editData)) {
            this.$set(editData, prop, undefined);
            this.errorTip(prop);
          }
          if (item.type === "number") {
            return returnVal ? Number(returnVal) : returnVal;
          }
          return returnVal;
          // return item.type === 'number' ? Number(returnVal) : returnVal
        }
        // 3、如果是复杂类型的testObj.a.b，则进入方法
        return this.getDynamicVal({
          type: "getVal",
          editData,
          props,
          custom,
        });
      } catch (error) {
        throw new Error(
          `getValue中出现错误${error} \n ${JSON.stringify(item)}`
        );
      }
    },
    /**
     * @description: input、select等的input事件和chagne事件
     * @param {*} value 当前输入或者选择的val
     * @param {*} editData 当前表单的form
     * @param {*} item 当前渲染的配置项
     * @param {*} type input | change
     * @param {*} custom 当使用 propList 范围金额，自定义组件时，拿到另一个的prop
     */
    setValue(value, editData, item, type, custom) {
      try {
        // 判断是否填写了item.prop
        const { prop, input, change } = item;
        // 如果没有填写prop，则抛出错误
        // 1、切割testObj.a.b成['testObj', 'a', 'b']
        const props = prop.split(".");
        // 2、当不是testObj.a.b的情况下，则直接赋值
        if (props.length === 1) {
          if (item.type === "number") {
            value = value ? Number(value) : value;
          }
          editData[custom || prop] = value;
        } else {
          // 3、如果是复杂类型的testObj.a.b，则进入方法
          this.getDynamicVal({
            type: "input",
            editData,
            props,
            value,
            item,
            custom,
          });
        }
        // 4、判断是否在配置中绑定了事件，绑定了，则需要调用
        type === "input" && input && input(value, editData);
        type === "change" && change && change(value, editData);
      } catch (error) {
        throw new Error(
          `setValue中出现错误${error} \n ${JSON.stringify(item)}`
        );
      }
    },
    /**
     * @description: 解析多层props（深层对象）, 例如：[testObj.a.b], 获取动态的val值或者设置深层对象的值
     * @param {*} type 判断当前是获取val，还是设置val
     * @param {*} editData 当前表单的form
     * @param {*} props 当前配置项的props，复杂类型的为testObj.a.b
     * @param {*} val 当前input输入的val值
     * @param {*} custom 当使用 propList 范围金额，自定义组件时，拿到另一个的prop
     * @return { null, string}  backVal 返回的值
     */
    getDynamicVal({ type, editData, props, value, item, custom }) {
      let backVal = null;
      // 1、循环['testObj', 'a', 'b']
      props.forEach((p, index) => {
        // 2、循环到最后一个时，将输入的值赋值给.b
        if (index === props.length - 1) {
          /**
           * - 如果是获取val的类型，则将结果赋值给backVal，并且返回出去
           * - 否则进入设置值的判断
           */
          if (type === "getVal") {
            // 手动给form表单中，添加缺少的字段值
            if (!((custom || p) in editData)) {
              this.$set(editData, custom || p, undefined);
              this.errorTip(props);
            }
            backVal = custom ? editData[custom] : editData[p];
          } else {
            // 当form数据源中，不存在字段时，需要弹出错误提示

            //  如果存在自定义组件（金额范围组件）的使用，则将value值，赋给editData[custom]
            if (custom) {
              editData[custom] = value;
            } else {
              /**
               * - 否则将输入的val值，赋值给.b，
               * - 例如：editData['testObj']['a']['b'] = val
               * */
              editData[p] = item.type === "number" ? Number(value) : value;
            }
          }
        } else {
          // 5、每次循环都将editData重新赋值
          editData = editData[p];
        }
      });
      return backVal;
    },
    /**
     * @description: 提示错误
     * @param {*} prop 当前不存的字段名
     */
    errorTip(prop, err) {
      const strErr = `错误：业务表表单form中不存在【${prop}】字段名，请检查！`;
      console.error(`🎈🎈🎈${err || strErr}`);
    },
    judgeType(obj) {
      return typeof obj === "object";
    },
    // 自动生成新增的form表单的rules
    autoRules() {
      const rulesObj = {};
      this.itemList.forEach((item) => {
        let itemArr = [];
        // 必填时的验证
        if (item.isReq) {
          itemArr.push({
            required: true,
            message: `请${
              ["input", "textarea"].includes(item.type) ? "输入" : "选择"
            }${item.label}`,
            trigger: ["input", "textarea"].includes(item.type)
              ? "blur"
              : "change",
          });
        }
        // 自定义规则的验证
        if (item.customRules) {
          itemArr = itemArr.concat(item.customRules);
        }
        // 数字类型的验证
        if (item.type === "number") {
          itemArr.push({
            type: "number",
            message: `${item.label}必须为数字值`,
            trigger: "blur",
          });
        }
        // console.log('itemitemitem--》', item, this.);
        // 手机号的验证 { validator: validatePhone, trigger: 'blur' }
        if (
          item.type &&
          (item.inputType === "phone" || item.ruleType === "phone")
        ) {
          itemArr.push({ validator: rulePhone, trigger: "blur" });
        }
        // 链接的验证
        if (item.type && item.ruleType === "link") {
          itemArr.push({ validator: ruleLink, trigger: "blur" });
        }
        // 整数的验证
        if (item.type && item.ruleType === "integer") {
          itemArr.push({ validator: ruleInteger, trigger: "blur" });
        }
        // 百分比的验证
        if (item.type && item.ruleType === "ratio") {
          itemArr.push({ validator: percentageRule, trigger: "blur" });
        }
        if (itemArr.length > 0) {
          rulesObj[item.prop] = itemArr;
        }
      });
      this.editRulesObj = rulesObj;
    },
    /**
     * @description: 重置表单功能
     * @author: huchao
     */
    resetForm() {
      // 重置表单
      this.$refs.editForm.resetFields();
      // 每次重置时调用搜索方法
      if (this.editConfig.seachFormFun) {
        this.editConfig.seachFormFun();
      }
    },
    /**
     * @description: 移除图片功能
     * @author: huchao
     */
    removeImg(key) {
      this.editData[key] = "";
    },
    uploadSuccess({ srcUrl, key }) {
      this.editData[key] = srcUrl;
      // 处理在修改的时候，上传图片出现2张预览图的bug
      if (this.dgConfigType === "edit") {
        this.editImgFlag = false;
      }
    },
    /**
     * @description: 在select组件中，循环对象枚举时，会将数字类型转为字符串，导致值的类型对不上，
     * 如果当前select的val为数字，则需要将字符串类型转为数字类型
     * @param {*} key 当前option的值
     * @param {*} selectVal editData[item.prop]当前select的val
     * @author: huchao
     */
    strChangeNum(key, selectVal) {
      if (typeof selectVal === "number") {
        const newKey = JSON.parse(JSON.stringify(key));
        return Number(newKey);
      }
      return key;
    },
    /**
     * @description: 处理form表单的按钮文字
     * @param { function，string } type 当前传入的文字参数
     */
    formButtonTextHandle(type) {
      /**
       * confirmButtonText： 确认按钮文字
       * cancelButtonText: 取消按钮文字
       */
      const { confirmButtonText: cfm, cancelButtonText: cel } = this.editConfig;
      if (type === "confirm") {
        if (!cfm) return "确定";
        return typeof cfm === "function" ? cfm() : cfm;
      }
      if (type === "cancel") {
        if (!cel) return "取消";
        return typeof cel === "function" ? cel() : cel;
      }
    },
    // 当前表单是否显示
    formItemShow(data, item) {
      if (item.formItemShow) {
        return item.formItemShow(data, item);
      }
      return true;
    },
    // 判断表单自定义按钮是否显示
    btnShowFlag(data, item) {
      if (typeof item.showFlag === "function") {
        return item.showFlag(data, item);
      }
      return true;
    },
    // 渲染选择框的option方法
    selectOption(editData, item) {
      if (typeof item.options === "function") {
        return item.options(editData, item);
      }
      return item.options;
    },
    // 提交表单方法
    submitForm() {
      const { getNestedComponentRef, submitForm } = this.editConfig;
      const getNestedRef = getNestedComponentRef
        ? getNestedComponentRef()
        : null; // 拿到内嵌的组件ref
      // 判断当前submitForm是否为函数
      const subIsObj = typeof submitForm !== "function";
      // 如果是对象，则调用对象中的submitForm，否则直接调用提交方法
      const fun = subIsObj ? submitForm.submitForm : submitForm;
      // beforeFormValidation方法，是点击提交时，在【表单校验前】去做的一些事情
      if (submitForm && subIsObj) {
        submitForm.beforeFormValidation();
      }
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          // 存在内嵌组件时
          if (getNestedRef) {
            getNestedRef.$refs.editForm.validate((flag) => {
              if (flag) {
                fun();
              }
            });
          } else {
            fun();
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.formStyle {
  padding: 10px;
  text-align: left;
  background: #fff;
}
.formStyle .el-select,
.formStyle .el-date-editor {
  width: 100%;
}
.el-dialog__wrapper .el-input__inner {
  max-width: 100% !important;
}
.seachStyle {
  margin-bottom: 12px;
  border: 1px solid #ededed;
  padding: 10px 10px 0 10px;
}
.rangeInput {
  width: 150px;
}
.rangeSpan {
  margin: 0 12px;
}
.toolsBtnWrap {
  display: block !important;
}
:deep.seachStyle .el-form-item--mini.el-form-item,
:deep.seachStyle .el-form-item--small.el-form-item {
  margin-bottom: 10px !important;
}

:deep .el-input.is-disabled .el-input__inner,
:deep .el-range-editor.is-disabled,
:deep .el-checkbox__input.is-disabled + span.el-checkbox__label,
:deep .el-radio__input.is-disabled + span.el-radio__label,
:deep .el-range-editor.is-disabled input {
  color: #3c3f42 !important;
}
</style>
