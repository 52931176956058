/**
 * Created by jiachenpan on 16/11/18.
 */
import CryptoJS from "crypto-js";
import { Message } from "element-ui";
export const exportDefault = "export default ";

export function isNumberStr(str) {
  return /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g.test(str);
}

// 首字母大小
export function titleCase(str) {
  return str.replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
}

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (("" + time).length === 10) time = parseInt(time) * 1000;
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === "a") return ["一", "二", "三", "四", "五", "六", "日"][value - 1];
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

export function formatTime(time, option) {
  time = +time * 1000;
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + "月" + d.getDate() + "日" + d.getHours() + "时" + d.getMinutes() + "分";
  }
}

//时间格式转换
export function timestampToTime(timestamp) {
  const date = new Date(timestamp);
  const Y = date.getFullYear() + "-";
  const M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
  const D = date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
  let h = null;
  let m = null;
  let s = null;
  if (date.getHours()) {
    h = date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
  } else {
    h = "00" + ":";
  }
  if (date.getMinutes()) {
    m = date.getMinutes() < 10 ? "0" + date.getMinutes() + ":" : date.getMinutes() + ":";
  } else {
    m = "00" + ":";
  }
  if (date.getSeconds()) {
    s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  } else {
    s = "00";
  }
  return Y + M + D + h + m + s;
}

//获取元素距离游览器最左边的距离
export function getOffsetLeft(obj) {
  let tmp = obj.offsetLeft;
  let val = obj.offsetParent;
  while (val != null) {
    tmp += val.offsetLeft;
    val = val.offsetParent;
  }
  return tmp;
}

export function encrypt(word, keyStr) {
  keyStr = keyStr ? keyStr : "abcdefgabcdefg12rgh12678";
  let srcs = CryptoJS.enc.Utf8.parse(word);
  let key = CryptoJS.enc.Utf8.parse(keyStr);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}
//解密
export function decrypt(word, keyStr) {
  keyStr = keyStr ? keyStr : "abcdefgabcdefg12rgh12678";
  let key = CryptoJS.enc.Utf8.parse(keyStr); //Latin1 w8m31+Yy/Nw6thPsMpO5fg==
  let decrypt = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}
export function timestampToTimes(timestamp) {
  var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y = date.getFullYear() + "-";
  const M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
  const D = date.getDate() + " ";
  var h = null;
  var m = null;
  var s = null;
  if (date.getHours()) {
    h = date.getHours() + ":";
  } else {
    h = "00" + ":";
  }
  if (date.getMinutes()) {
    m = date.getMinutes() + ":";
  } else {
    m = "00" + ":";
  }
  if (date.getSeconds()) {
    s = date.getSeconds();
  } else {
    s = "00";
  }
  return Y + M + D + h + m + s;
}
export function timestampToTime1(timestamp) {
  var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  const Y = date.getFullYear() + "/";
  const M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "/";
  const D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  var h = null;
  var m = null;
  var s = null;
  if (date.getHours()) {
    h = date.getHours() + ":";
  } else {
    h = "00" + ":";
  }
  if (date.getMinutes()) {
    m = date.getMinutes() + ":";
  } else {
    m = "00" + ":";
  }
  if (date.getSeconds()) {
    s = date.getSeconds();
  } else {
    s = "00";
  }
  return Y + M + D + h + m + s;
}
/**
 *
 * @param {数据源list} list
 * @param {要选中的list} checkList
 * @param {表格绑定的ref}} refName
 * @param {比对值(唯一)} comparisonVal
 */
export function ehoCheck(list, checkList, ref, comparisonVal) {
  this.$nextTick(() => {
    list.forEach((item) => {
      checkList.forEach((item_2) => {
        if (item[comparisonVal] == item_2[comparisonVal]) {
          this.$refs[ref].toggleRowSelection(item, true);
        }
      });
    });
  });
}
/**
 * 密码校验规则
 */
export function checkPassWordRule(rule, value, callback) {
  let regList = [/\d/g, /[a-z]/g, /[A-Z]/g, /\W|_/g];
  let result = regList.map((ele) => ele.test(value));
  if (value.length < 8 || value.length > 18 || result.filter((item) => item == true).length < 4) {
    callback(new Error("密码格式错误"));

    // callback(new Error('密码需要8-18位并且满足四种字符的组合: 1.小写字母 2.大写字母 3.数字 4.特殊字符'))
    Message.error("密码需要8-18位并且满足四种字符的组合: 1.小写字母 2.大写字母 3.数字 4.特殊字符");
  } else {
    callback();
  }
}
export function checkPassWordRuleCo(rule, value, callback) {
  let reg = /^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z]{8,18}$/;
  let result = reg.test(value);
  if (!value || value == "") {
    callback(new Error("请输入密码"));
  } else if (!result) {
    callback(new Error("密码需要8-18位并且满足两种字符的组合: 1.大小写字母 2.数字 "));
  } else {
    callback();
  }
}
export function checkPassWordRules(rule, value, callback) {
  let rules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,18}$/;
  let result = rules.test(value);
  if (!value || value == "") {
    callback(new Error("请输入密码"));
  } else if (!result) {
    callback(new Error("密码需要8-18位并且满足三种字符的组合: 1.小写字母 2.大写字母 3.数字"));
  } else {
    callback();
  }
  // let regList = [/\d/g, /[a-z]/g, /[A-Z]/g, /\W|_/g];
  // let result = regList.map((ele) => ele.test(value));
  // if (!value || value == "") {
  //   callback(new Error("请输入密码"));
  // } else if (
  //   value.length < 8 ||
  //   value.length > 18 ||
  //   result.filter((item) => item == true).length < 4
  // ) {
  //   callback(
  //     new Error(
  //       "密码需要8-18位并且满足四种字符的组合: 1.小写字母 2.大写字母 3.数字 4.特殊字符"
  //     )
  //   );
  // } else {
  //   callback();
  // }
}
export function checkPassWordRules1(value) {
  let regList = [/\d/g, /[a-z]/g, /[A-Z]/g, /\W|_/g];
  let result = regList.map((ele) => ele.test(value));
  if (!value || value == "") {
    return "请输入密码";
  } else if (value.length < 8 || value.length > 18 || result.filter((item) => item == true).length < 4) {
    return "密码需要8-18位并且满足四种字符的组合: 1.小写字母 2.大写字母 3.数字 4.特殊字符";
  } else {
    return true;
  }
}

/**
 *
 * @param {接口返回的blob二进制文件流} res
 * @param {文件名} fileName
 */
export function downloadFileMethods(res, fileName) {
  let blob = new Blob([res]);
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement("a");
  a.download = fileName;
  a.href = url;
  a.click();
}
