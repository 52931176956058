var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.row[this.propName]
    ? _c(
        "el-button",
        {
          staticClass: "copy",
          attrs: {
            "data-clipboard-text": _vm.row[this.propName],
            type: "text",
            title: "点击复制",
          },
          on: { click: _vm.copyClick },
        },
        [_c("i", { staticClass: "el-icon-document-copy" })]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }