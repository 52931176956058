<!--
 * @Author:huchao
 * @Date: 2020-07-20 16:00:09
 * @LastEditTime: 2022-12-22 13:10:01
 * @LastEditors: huchao huchao-khd@bestpay.com.cn
 * @Description: In User Settings Edit
-->
<template>
  <div class="fileUploadContainer">
    <el-upload
      :element-loading-text="loadingTextD"
      element-loading-spinner="el-icon-loading"
      :ref="ids"
      :id="ids"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :file-list="fileList"
      :accept="accepted"
      list-type="picture-card"
      :multiple="multiple"
      :on-exceed="handleExceed"
      :before-upload="handleBeforeUpload"
      :before-remove="beforeRemove"
      :on-progress="handleUploadProgress"
      :on-change="handleChange"
      :auto-upload="true"
      :on-success="handleSuccess"
      :on-error="handleError"
      :limit="limit"
      :http-request="httpRequestLogo"
    >
    <!-- <template>
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
    </template> -->
    <template>
      <i class="el-icon-plus"/>
      <div slot="tip" class="el-upload__tip">
        {{ tipsD }}
      </div>
    </template>
      <!-- <Progress v-if="flag" type="circle" :percentage="uploadPercent" style="margin-top:30px;"></Progress> -->
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" title="查看" append-to-body>
      <el-image style="width: 100%;" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
// 组件使用说明
// -----------------------------参数------------------------------------
/**
 * @param {String} ids refs和 id
 * @param {Number} limit 限制文件上传个数
 * @param {String} fileType 文件类型
 * @param {Arrar}  btnText 按钮中显示的文字内容 ['选取文件','上传']
 * @param {String} url 文件上传的地址
 * @param {String} loadingText 加载中的文字
 * @param {Arrar}  fileSize 文件的大小个单位 [3,'kb']或[3.'m']
 * @param {String} listType 文件的展示方式 'text'  'picture' 'picture-car'
 * @param {String} fileUrl 图片预览图：用于编辑时图片回显
 * @param {String} dgConfigType 状态：当前是查看弹框、编辑 还是新增
 * @returns null
 */

 import {
    // el-upload,
    // el-dialog,
    Message,
    Progress,
    Image
} from 'element-ui';
// import http from '@/utils/http';

// import common from '@/utils/common';
export default {
  components: {
    // el-upload,
    // el-dialog,
    Progress,
    ImageCom: Image
  },
  props: {
    limit: '',
    ids: '',
    multiple: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    fileType: {
      type: Array,
      default: function () {
        return [];
      },
    },
    btnText: {
      type: Array,
      default: function () {
        return [];
      },
    },
    url: {
      type: String,
      default: '',
    },
    loadingText: {
      type: String,
      default: '',
    },
    // 数组，[大小,单位]
    fileSize: {
      type: Array,
      default: function () {
        return [];
      },
    },
    listType: {
      type: String,
      default: function () {
        return '';
      },
    },
    headers: {
      type: Object,
      default: function () {
        return {};
      },
    },
    fileUrl: {
      type: String,
      default: ''
    },
    // 照片回显时使用
    fileListObj: {
      type: Array,
      default: function () {
        return []
      }
    },
    dgConfigType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fileList: [],
      baseUrl: process.env.BASE_API,
      dialogVisible: false,
      dialogImageUrl: '',
      listTypeD: '',
      fileSizeD: '',
      btnTextD: [],
      tipsD: '',
      accepted: '',
      loadingTextD: '',
      fileAllType: [
        'doc',
        'docx',
        'xls',
        'xlsx',
        'pdf',
        'PDF',
        'jpg',
        'JPG',
        'eml',
        'mp4',
      ],
      colors: [
        { color: '#fff143', percentage: 10 },
        { color: '#4b5cc4', percentage: 20 },
        { color: '#ff7500', percentage: 30 },
        { color: '#ff4777', percentage: 40 },
        { color: '#3b2e7e', percentage: 50 },
        { color: '#bddd22', percentage: 60 },
        { color: '#a3d900', percentage: 70 },
        { color: '#70f3ff', percentage: 80 },
        { color: '#8d4bbb', percentage: 90 },
        { color: '#00bc12', percentage: 100 },
      ],
      uploadPercent: 0,
      flag: true,
      interval: '',
      loading: false,
      triggerHttp: false, // 用来解决请求后，更改当前form的url，导致触发watch监听，预览图会重置的问题
    };
  },
  watch: {
    fileUrl(newVal, oldVal) {
        if (!this.triggerHttp && (oldVal !== newVal)) {
          this.fileList = this.fileListObj;
        }
    }
  },
  methods: {
    submitUpload() {
      this.$refs.upload.submit();
    },
    httpRequestLogo(req) {
      this.triggerHttp = true; // 触发了请求
      let params = new FormData(); //创建form对象
      params.append('file', req.file, req.file.name);//通过append向form对象添加数据
      this.$http('uploadImage', params).then((res) => {
        if (res && res.success) {
          const srcUrl = res.result;
          this.$emit('uploadSuccess', { srcUrl, key: this.ids });
          this.$nextTick(e=>{
            this.triggerHttp = false;
          })
        }
      });
    },
    handleBeforeUpload(file) {
      const fileType = file.name
        .substring(file.name.lastIndexOf('.') + 1)
        .toLowerCase();
      let size;
      if (this.fileSize === 0) {
        size = file.size / 1024 / 1024 < 5;
      } else {
        if (
          this.fileSize[1].toLowerCase() === 'kb' ||
          this.fileSize[1].toLowerCase() === 'm'
        ) {
          if (this.fileSize[1].toLowerCase() === 'kb') {
            size = file.size / 1024 < this.fileSize[0];
          } else {
            size = file.size / 1024 / 1024 < this.fileSize[0];
          }
        } else {
          size = false;
        }
      }
      if (this.fileType.indexOf(fileType) < 0) {
        Message.error('上传文件格式错误!');
        return false;
      }
      if (!size) {
        if (this.fileSize === 0) {
          Message.error('上传文件大小不能超过 5M');
        } else {
          Message.error(
            `上传文件大小不能超过 ${this.fileSize[0]}${this.fileSize[1]}`
          );
        }
        return false;
      }
      return file;
    },
    // 图片移除时处理数据
    handleRemove(file, fileList) {
      const item = [];
      const key = this.ids
      fileList.forEach(el => {
          item.push(el.url);
      });
      this.$emit('removeImg', key);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 判断图片数量
    handleExceed(files, fileList) {
      Message.warning(
        `当前限制上传 ${this.limit} 个文件`
        // 本次选择了 ${files.length} 个文件,共选择了 ${files.length + fileList.length} 个文件
      );
    },
    beforeRemove(file, fileList) {}, // 上传图片成功事件
    handleSuccess(response, file, fileList) {
      clearInterval(this.interval);
      this.loading = false;
      this.uploadPercent = file.percentage;
      const timeOut = setTimeout(() => {
        this.flag = false;
        this.uploadPercent = 0;
        clearTimeout(timeOut);
      }, 1000);
      this.$refs[this.ids].clearFiles();
    },
    // eslint-disable-next-line handle-callback-err
    handleError(error, file, fileList) {
      this.loading = false;
      this.flag = false;
      this.uploadPercent = 0;
      console.error(error);
      Message.error('失败了');
    },
    handleChange(file, fileList) {},
    handleUploadProgress(event, file, fileList) {
      if (file.status === 'ready') {
        this.flag = true;
        this.loading = true;
        this.interval = setInterval(() => {
          if (this.uploadPercent >= 99) {
            clearInterval(this.interval);
            return;
          }
          this.uploadPercent += 1;
        }, 300);
      }
    },
  },
  mounted() {
    this.fileList = this.fileListObj;
    // 按钮内显示的字
    if (this.btnText.length === 0) {
      this.btnTextD = ['选取文件', '上传'];
    } else {
      this.btnTextD = this.btnText;
    }
    // 展示的方式，图片有 picture，picture-card
    if (this.listType.length !== '') {
      this.listTypeD = this.listType;
    }
    // 提示内容
    if (this.fileType.length === 0) {
        this.tipsD = '可以上传doc/docx/xls/xlsx/pdf/PDF/jpg/JPG/eml/mp4文件,且文件不能大于5M'
      if (this.fileSize > 0) {
        this.tipsD = `${this.this.tipsD}${this.fileSize[0]}${this.fileSize[1]}`;
      }
    } else {
      this.tipsD = '可以上传';
      if (this.fileSize === 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.fileType.length; i++) {
          if (i === this.fileType.length - 1) {
            this.tipsD += `${this.fileType[i]}文件,且文件不能大于5M`;
          } else {
            this.tipsD += `${this.fileType[i]}/`;
          }
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.fileType.length; i++) {
          if (i === this.fileType.length - 1) {
            this.tipsD +=
              `${this.fileType[i]
              }文件,且文件不能大于${
              this.fileSize[0]
              }${this.fileSize[1]}`;
          } else {
            this.tipsD += `${this.fileType[i]}/`;
          }
        }
      }
    }
    if (this.fileType.length !== 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.fileType.length; i++) {
        if (i === this.fileType.length - 1) {
          this.accepted += `.${this.fileType[i]}`;
        } else {
          this.accepted += `.${this.fileType[i]},`;
        }
      }
    }
    if (this.loadingText === '') {
      this.loadingTextD = '处理中...';
    } else {
      this.loadingTextD = this.loadingText;
    }
  },
};
</script>
<style type="text/css" scoped>
/* .el-loading-spinner {
    top:15%!important;
    margin-top: -50px!important;
} */
:deep .el-upload-list__item .el-icon-zoom-in,
:deep .el-upload-list__item .el-icon-delete{
  font-size: 22px !important;
}
:deep .el-upload-list__item {
  transition: none ;
}
</style>
