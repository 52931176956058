// import Vue from 'vue'
// import Vuex from 'vuex'
import app from '#/store/modules/app'
import user from '#/store/modules/user'
import docList from './modules/docList'
import picList from './modules/picList'
import getters from './getters'
import menuListStore from './modules/menuList'
import siteListStore from './modules/siteList'
import menu from '#/store/modules/menu'
import vote from './modules/vote'
import merpicList from './modules/merpicList'
import list from './modules/list'
import mad from './modules/mad'
import car from './modules/car'
import appLayout from './modules/appLayout'
import loadingState from './modules/loadingState'
// Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    user,
    menu,
    docList,
    picList,
    menuListStore,
    siteListStore,
    vote,
    merpicList,
    list,
    mad,
    car,
    appLayout,
    loadingState
  },
  getters,
  state: {
    zd:'',
    lm:''
  },
  mutations: {
    bus (state,a) {
      state.zd = a.res1
      state.lm = a.res2
    }
  }
})

export default store
