export default {
   loginPc: {
      angang: {
         angangLogin: {
            vLogin: {
               title: '会员登录',
               tabTitle: ['账号密码登录', '手机验证码登录'],
               nameLabel: '账户',
               namePlace: '请输入账号',
               pwdLabel: '密码',
               pwdPlace: "请输入密码",
               codeLabel: "验证码",
               codePlace: "请输入验证码",
               remPwd: "记住密码",
               phoneLabel: "+86（中国大陆）",
               phonePlace: "请输入手机号",
               msgPlace: "请输入短信验证码",
               loginAuto: "7天内自动登录",
               loginBtn: "登录",
               forgetLabel: "忘记密码?",
               noAccount: "没有账号?",
               gotoSign: "去注册",
               getMsgCode: "获取验证码",
               surplus: "剩余",
               rules: {
                  phoneError: "请输入正确的手机号",
                  mailboxError: '请输入正确的邮箱',
                  codeError: "验证码输入错误"
               }
            }
         }
      },
      regPc: {
         regBus: {
            vLogin: {
               sign: '注册',
               signM: ['手机号', '邮箱'],
               enterprise: ['企业', '个人'],
               selected: '请选择',
               haves: '手机号已存在',
               readAgree: '我已阅读并同意',
               reset: '重置',
               title: '会员登录',
               tabTitle: ['账号密码登录', '手机验证码登录'],
               nameLabel: '账户',
               namePlace: '请输入账号',
               companyNamePlace: '请输入公司名称',
               youNamePlace: '请输入姓名',
               pwdLabel: '密码',
               pwdPlace: "请输入密码",
               pwdsPlace: '请输入6-20位由数字或字母组成的密码',
               codeLabel: "验证码",
               codePlace: "请输入验证码",
               numCodePlace: "请输入6位数字验证码",
               remPwd: "记住密码",
               phoneLabel: "+86（中国大陆）",
               phonePlace: "请输入手机号",
               mailboxPlace: "请输入邮箱",
               msgPlace: "请输入短信验证码",
               loginAuto: "7天内自动登录",
               loginBtn: "登录",
               forgetLabel: "忘记密码?",
               noAccount: "没有账号?",
               gotoSign: "去注册",
               atOnceSign: "立即注册",
               vipSign: '《会员注册协议》',
               existing: '已有账号？直接登录',
               getMsgCode: "获取验证码",
               surplus: "剩余",
               close: '关闭',
               agreement: '会员注册协议',
               none: '暂无信息',
               rules: {
                  phoneError: "请输入正确的手机号",
                  mailboxError: '请输入正确的邮箱',
                  codeError: "验证码输入错误"
               },
               check: "请您先阅读会员协议",
               messageError: ['操作成功', '操作失败']
            }
         }
      },
      loginUi: {
         forgetPw: {
            title: "找回密码",
            enterPhone: "输入手机号或邮箱",
            codeLabel: "输入验证码",
            pwdPlace: "输入新密码",
            confirm: '确定',
            toLogin: "去登录",
            pwdError: "请输入新密码",
            pwdsPlace: '请输入6-12位由数字或字母组成的新密码',
            phonePlace: "请输入手机号或邮箱",
            phoneError: "请输入手机号或邮箱",
            phoneNoSign: "手机号未注册",
            codeError: "验证码输入错误",
            codePlace: "请输入验证码",
            numCodePlace: "请输入6位数字验证码",
            codes: "获取验证码",
            remain: "剩余",
            s: "秒",
            verCodeError: "验证码输入错误",
            success: '操作成功',
            phoneOremil: '请输入手机号或邮箱',
            error: '操作失败'
         },
         window_login: {
            title: "登录",
            accountPlace: "输入账号",
            pwdPlace: "输入密码",
            phoneCodeLogin: "手机验证码登录",
            login: "登录",
            forgetPwd: "忘记密码？",
            toSign: "去注册",
            pwdError: "请输入密码",
            pwdErrorPlace: "请输入6-20位由数字或字母组成的密码",
            phoneRules: "输入账号",
            tipPwdError: "密码填写错误",
            phoneNoSign: "手机号未注册",
            enterPhone: "输入手机号或邮箱",
            error: '操作失败',
            phoneOremil: '请输入正确的手机号',
            numCodePlace: "请输入6位数字验证码",
            codePlace: "请输入验证码",
            success: '操作成功',
            s: "秒",
            codes: "获取验证码",
            remain: "剩余",
         },
         window_loginCode: {
            title: "手机登录",
            enterPhone: "输入手机号",
            codeLabel: "输入验证码",
            codes: "获取验证码",
            login: "登录",
            toSign: "去注册",
            accountPwd: "账号密码登录",
            phonePlace: "请输入手机号",
            phoneError: "请输入正确的手机号",
            codePlace: "请输入验证码",
            numCodePlace: "请输入6位数字验证码",
            remain: "剩余",
            s: "秒",
         }
      },
      loginBus: {
         wait: {
            wait: '请等待审核',
            mobile: '客服电话',
            home: '返回首页',
            messageError: ['操作成功', '操作失败']
         }
      }
   },
   disChannel: {
      order: {
         storeAbnormalOrder: {
            plat: "平台",
            platPlaceholder: "请输入平台订单号",
            platOrder: "平台订单号",
            promptTitle: '确定是否重新推送平台数据？'
         },

      }
   },
   car_mod: {
      car: {
         index: {
            carUi: {
               shoppingcar: {
                  thead: ['全选', '商品信息', '单价', '数量', '小计', '操作'],
                  btnPrompt: '去凑单',
                  delete: '删除',
                  selSale: '已选促销',
                  edit: '修改',
                  reset: '刷新',
                  saleList: '促销列表',
                  confirm: '确定',
                  cancel: '取消',
                  car: '购物车'
               }
            },
            checkboxAll: '全选',
            deletePrompt: '删除选择的商品',
            goodsPrompt: ['已选', '件商品', '合计(不含运费)'],
            subtitle: '立即结算',
            confirm: '确定',
            cancel: '取消',
            selectNonePrompt: '您还未选中商品',
            confirmPrompt: '确定删除所选商品？',
            alertCont: '请选择要删除的商品',
            prompt: '提示',
            successPrompt: '删除成功',
            stockprompt: ['库存小于起订量无法购买', '购买数量不能高于商品库存', '购买数量不能小于起订量', '库存不足无法购买']
         }
      },
      accounts: {
         index: {
            title: "填写和提交订单信息",
            getGoodsMsg: "收货信息",
            logInfo: '物流信息',
            effectTime: "预计交期",
            remark: "备注信息：",
            newAddress: "使用新地址",
            payWay: "支付方式",
            onlinePay: "在线支付",
            Offline: '线下支付',
            goodsList: "商品清单",
            useCoupons: "使用优惠券",
            selectPlace: "请选择",
            payIntegral: "支付积分",
            payIntegralPlace: "请输入使用的积分",
            goodsNum: "商品总件数：",
            goodsMoney: "商品总金额：",
            goodsFreight: "商品运费：",
            couponsMoney: "优惠金额：",
            memberEquities: "会员权益",
            submitOrder: "提交订单",
            payable: "应付总额：",
            noInventory: "没有库存",
            noOrder: "您还没有订单",
            addAddress: "请添加收货地址",
            giftBagFor: "礼包兑换",
            forSuccess: "兑换成功",
            forFail: "兑换失败",
            goOnFor: "继续兑换",
            orderResults: "下单结果",
            payOrderSuccess: "下单成功",
            payOrderFail: "下单失败",
            prizeFor: "奖品兑换",
            msgError: "该优惠券不满足条件",
            subTipError: "购物车商品不存在",
            noAuthentication: "没有认证"
         },
         account_ui: {
            addAddress: {
               area: "所在地区：",
               selectPlace: "请选择",
               addressDetails: ["详细地址：", "详细地址，街道、门牌号等"],
               consignee: "收货人：",
               phone: "手机号码：",
               default: "设为默认",
               confirm: '确定',
               cancel: '取消',
               validatePhoneNone: "请输入手机号码",
               validatePhoneError: "请输入正确的手机号",
               validateAddressNone: "请填写详细地址",
               validateNameNone: "请填写收货人",
               novalidatePhone: "手机号不正确，请重新输入",
               subsuccess: '操作成功',
               suberror: '操作失败'
            },
            addAddressDt: {
               area: "所在地区：",
               selectPlace: "请输入国家",
               selectPlaceT: "请输入地区",
               addressDetails: ["详细地址：", "详细地址，街道、门牌号等"],
               consignee: "收货人：",
               phone: "手机号码：",
               default: "设为默认",
               confirm: '确定',
               cancel: '取消',
               validatePhoneNone: "请输入手机号码",
               validatePhoneError: "请输入正确的手机号",
               validateAddressNone: "请填写详细地址",
               validateNameNone: "请填写收货人",
               novalidatePhone: "手机号不正确，请重新输入",
               subsuccess: '操作成功',
               suberror: '操作失败'
            },
            accountsItem: {
               goodsMsg: "商品信息",
               specification: "规格",
               price: "价格",
               amount: "数量",
               totle: "小计",
               gifts: "赠品",
               courier: "普通快递",
               remark: "备注信息：",
            }
         }

      }
   },
   pay_mod: {
      pay: {
         index: {
            submitOrderSuccess: "您的订单已成功提交，请尽快付款",
            orderCode: "订单号：",
            totalMoney: "总金额：",
            changePayMoney: "修改支付金额",
            orderMoney: "订单总金额：",
            payMoney: "已支付金额：",
            thisPayMoney: "本次支付金额：",
            editPayMoney: "可自定义编辑付款金额",
            payMoneyPlace: "输入本次支付金额",
            payWay: "支付方式",
            baseAccount: "钱包余额",
            accountBalance: "优点：",
            thisOrderDiscount: "本单抵扣：",
            otherPayWay: "其他支付方式",
            confirm: "确 定",
            cancel: '取 消',
            inputPayPwd: "请输入支付密码",
            blessPoint: "元",
            WeChatPay: "微信扫码支付",
            user: ["账户一", "账户二", "账户三"],
            editPayMsg: "自定义金额不能大于订单总金额",
            payMsg: "支付金额不能小于等于0",
            noPayWay: "暂无支付方式",
            payWayPlace: "请选择支付方式",
            prompt: "提示",
            ensure: "确定",
            surePwdMsg: "密码长度不足6位",
            noMoney: "账户余额不足",
            paySuccess: "支付成功",
            upSuccess: "提交成功",
            payFail: "支付失败",
            queryOrder: "查看订单",
            backHome: "返回首页"
         }
      }
   },
   collectionPc: {
      collectionShop: {
         goods: "商品",
         shop: "店铺",
         batchManagement: "批量管理",
         allSelect: "全选",
         delete: '删除',
         cancelManagement: "取消管理",
         message: ["请勾选您要删除的店铺", "请勾选您要删除的商品"],
      },
      index: {
         title: '我的收藏',
         iconTitle: '批量管理',
         selectAll: '全选',
         deleteBtn: '删除',
         deletePrompt: "请勾选您要删除的商品",
         cancelBtn: '取消管理',
         goodsError: {
            '1': '库存不足',
            '2': '已下架',
            '3': '已失效'
         },
         nextPageError: '未获取到路经'
      },
      indexA: {
         bidCollect: "竞价收藏",
         shopCollect: "店铺收藏",
         goodsCollect: "商品收藏",
         batchManagement: "批量管理",
         allSelect: "全选",
         delete: '删除',
         cancelManagement: "取消管理",
         message: ["请勾选您要删除的包裹", "请勾选您要删除的店铺", "请勾选您要删除的商品"],
      },
      history: {
         title: '我的足迹',
         iconTitle: '批量管理',
         selectAll: '全选',
         deleteBtn: '删除',
         deletePrompt: "请勾选您要删除的商品",
         cancelBtn: '取消管理',
         noHistory: '暂无足迹',
         goodsError: {
            '1': '库存不足',
            '2': '已下架',
            '3': '已失效'
         },
         nextPageError: '未获取到路经'
      },
      historyRow: {
         title: "我的收藏",
         goodsName: "商品名称",
         price: "价格",
         operation: "操作",
         cancelCollect: "取消收藏",
         noGoods: "暂无商品",
      },

   },
   invoice_pc: {
      myInvoice: {
         myInvo: {
            title: "我的发票",
            tabTitle: ["发票信息", "开具发票", "增票资质"],
            thead: [
               "开票时间",
               "发票类型",
               "开票类型",
               "开票名称",
               "纳税人识别号",
               "开票金额",
               "状态",
            ],
            userinvType: ["电子普通发票", "增值税纸质普通发票", "增值税专用发票"],
            userinvSort: ["个人", "单位"],
            prompt: ["已提交", "已开票", "开票失败"],
            prompt1: ["已提交", "开票失败", "已开票"],
            edit: "编辑",
            explain:
               "我们会在一个工作日内完成审核工作,注意有效增值税发票资质仅为1个",
            companyName: "单位名称",
            companyNamePlace: "请输入单位名称",
            userinvNo: "纳税人识别号",
            userinvNoPlace: "请输入纳税人识别号",
            userinvAdd: "开票地址",
            userinvAddPlace: "请输入开票地址",
            userinvPhone: "开票电话",
            userinvPhonePlace: "请输入开票电话",
            userinvBankname: "开户行",
            userinvBanknamePlace: "请输入开户行",
            userinvBankno: "账号",
            userinvBanknoPlace: "请输入账号",
            addImg: "添加图片",
            qualification: "一般纳税人资格证明",
            userinvUname: "收票人姓名",
            userinvUnamePlace: "请输入收票人姓名",
            userinvUphone: "收票人手机",
            userinvUphonePlace: "请输入收票人手机",
            uAddress: "收票人地址",
            userinvUadd: "详细地址",
            placeholder: "请输入详细地址",
            confirm: "确定",
            cancel: "取消",
            qaPrompt: [
               "您提交的增票资质审核失败",
               "您提交的增票资质待审核，请耐心等待",
            ],
            applyBtn: "重新申请",
            tabs: ["全部", "已提交", "已开票", "开票失败"],
            errorPrompt: [
               "开票电话输入不正确",
               "收票人手机号输入不正确",
               "请输入正确纳税人识别号",
               "账号为纯数字",
               "请填写必填项",
               "图片格式为常见的jpg、png、gif格式",
               "上传的图片大小不能超过 5MB",
            ],
            memo: "备注",
         },
      },
      invoiceInfo: {
         Info: {
            title: "新增发票",
            addTitle: "新增发票信息",
            thead: ["名称", "操作", "公司名称", "纳税人识别号"],
            edit: "编辑",
            delete: "删除",
            diaTitle: "发票抬头",
            editInfo: "编辑发票信息",
            tabType: ["个人", "单位"],
            userinvAdd: '开票地址',
            userinvAddPlace: '请输入开票地址',
            userinvPhone: '开票电话',
            userinvPhonePlace: '请输入开票电话',
            userinvBankname: "开户行",
            userinvBanknamePlace: "请输入开户行",
            userinvBankno: '账号',
            userinvBanknoPlace: '请输入账号',
            qualification: '一般纳税人资格证明',
            explain: '我们会在一个工作日内完成审核工作,注意有效增值税发票资质仅为1个',
            invoiceName: "发票名称",
            companyName: "单位名称",
            companyNamePlace: "请输入单位名称",

            infoError: ["请填写完整信息"],
            userinvMemberPlace: "请输入开票名称",
            userinvNo: "纳税人识别号",
            userinvNoPlace: "请输入纳税人识别号",
            userinvNoError: ["请填写纳税人识别号", "纳税人识别号为字母、数字"],
            userinvUname: "收票人姓名",
            userinvUnamePlace: "请输入收票人姓名",
            userinvUphone: "收票人手机",
            userinvUphonePlace: "请输入收票人手机",
            userinvUphoneError: "请输入正确手机号",
            userinvUeamil: "收票人邮箱",
            userinvUeamilPlace: "请输入收票人邮箱",
            userinvUeamilError: "请输入正确邮箱号",
            address: "收票人地址",
            placeholder: "请输入详细地址",
            addressDetails: "详细地址",
            userinvUadd: "请输入详细地址",
            confirm: "确定",
            cancel: "取消",
            diaDeletePrompt: "确认删除此条发票",
            success: "操作成功",
            error: "操作失败",
         },
      },
      issueInvoice: {
         issue: {
            title: "开具发票",
            orderNo: "订单号",
            payName: "实付款",
            wInvoice: "开具发票",
            diaTitle: "发票抬头",
            invoicePrompt: "电子普通发票与普通发票具备同等法律效力,可支持报销入账",
            invoiceNotice: "发票须知",
            invoiceTypeTitle: "发票类型",
            invoiceType: ["电子普通发票", "增值税纸质普通发票", "增值税专用发票"],
            invoiceTitle: "发票抬头",
            tabType: ["个人", "单位"],
            invoiceName: "开票名称",
            invoiceNamePlace: "请输入开票名称",
            placeholderSelect: "请选择",
            selectHave: "选择已有",
            userinvUname: "收票人姓名",
            userinvUnamePlace: "请输入收票人姓名",
            userinvUphone: "收票人手机",
            userinvUphonePlace: "请输入收票人手机",
            userinvMember: "单位名称",
            userinvMemberPlace: "请输入单位名称",
            address: "收票人地址",
            userinvUadd: "请输入详细地址",
            userinvAdd: "开票地址",
            userinvAddPlace: "请输入开票地址",
            userinvNo: "纳税人识别号",
            userinvNoPlace: "请输入纳税人识别号",
            userinvPhone: "开票电话",
            userinvPhonePlace: "请输入开票电话",
            userinvBankname: "开户行",
            userinvBanknamePlace: "请输入开户行",
            userinvBankno: "账号",
            userinvBanknoPlace: "请输入账号",
            addImg: "添加图片",
            userinvUEamilRules: "请输入收票人邮箱",
            userinvUTrueEamilRules: "请输入正确的邮箱格式",
            userinvUnameRules: "请输入收票人名称",
            userinvUphoneRules: "请输入正确的电话号码",
            userinvUaddRules: "请输入收票人地址",
            userinvNoRules: "请输入纳税人识别号",
            confirm: "确定",
            cancel: "取消",
            errorPrompt: [
               "请填写必填项",
               "请输入正确纳税人识别号",
               "请填写正确的手机号",
               "请填写详细地址",
               "请填写正确的开票电话",
            ],
            imgError: [
               "图片最多上传一张",
               "图片格式为常见的jpg、png、gif格式",
               "上传的图片大小不能超过 5MB!",
            ],
         },
      },
   },
   pc: {
      evaluate: {
         myComment: {
            title: '我的评价',
            headerTab: ['商品', '评价', '评论内容'],
            score: '评分'
         },
         Comment: {
            title: '评论商品',
            subbutton: '提交',
            failed: '评论失败',
            message: '评论成功',
            evPrompt: '商铺星星评分要完整',
            imglimitSize: '上传的图片大小不能超过 5MB!',
            imgFormat: '图片格式为常见的jpg、png、gif格式'
         },
         myConsult: {
            customerService: '客服',
            noReply: '暂无回复'
         }
      },
      coupon: {
         couponList: {
            couponTab: ['可用优惠券', '已使用', '已过期'],
            couponUi: {
               unit: ['元', '减', '折'],
               dateTitle: '有效期：',
               couponNone: '暂无优惠券'
            },

         }
      },
      order: {
         index: {
            orderNoPlace: '请输入订单编号',
            search: '查询',
            confirm: '确定',
            cancel: '取消',
            userName: '姓名',
            userNamePlace: '请输入姓名',
            userCard: '身份证号',
            userCardPlace: '请输入身份证号',
            address: '所在地区:',
            placeholderSelect: '请选择',
            addressInfo: '详细地址：',
            addressInfoPlace: '详细地址，街道、门牌号等',
            email: '联系邮箱',
            emailPlace: "请输入邮箱",
            fax: '传真',
            faxPlace: '请输入传真',
            tips: '注：姓名填写确认后不可修改，请仔细检查',
            phoneError: ['请输入正确手机号', '请输入手机号'],
            cardError: ['请输入正确的身份证号', '请输入身份证号'],
            nav: '我的订单',
            rules: ['请选择省份', '请选择城市', '请选择区', '请填写详细地址'],
            certificationTitle: '信息完善',
            tabTitle: ['全部', '待付款', '待发货', '待收货', '已完成', '已取消', '售后'],
            tabTitleHy: ['全部', '待付款', '待转单', '生产中', '已出库', '已完成', '已取消',],
            seeDetail: '查看详情',
            refundState: {
               "0": "提交申请成功，等待卖家审核",
               "1": "审核通过",
               "2": "买家发货中...",
               "3": "卖家收到货",
               "4": "退款中",
               "5": "卖家拒绝申请",
               "6": "卖家拒绝收货",
               "7": "退款完成",
               "-1": "撤销退款申请",
               "8": "退款完成",
               "-2": "卖家拒绝申请",
               "15": "提交申请成功，等待卖家审核",

            },
            msgPrompt: ['是否确认收货', '确认取消此订单', '暂无快递信息', '合同签署中']

         },
         order_ui: {
            orderItem: {
               order: "订单",
               name: "收货人",
               orderState: "订单状态",
               realPay: "实付金额",
               operation: "操作",
               orderDetails: "订单详情",
               seeDetails: "订单详情",
               refund: "退款金额",
               serviceType: "服务类型",
               processState: "处理状态",
               orderNum: "订单编号：",
               yetOrder: "已下单",
               atOncePay: "立即支付",
               cancelOrder: '取消订单',
               signedContract: "签署合同",
               viewContract: "查看合同",
               viewProgress: "查看进度",
               viewOrder: "查看订单",
               confirmGoods: "确认收货",
               viewLogistics: "查看物流",
               evaluation: ["已评价", "评价"],
               refundNum: "退款编号：",
               orderTime: "下单时间：",
               effectTime: "预计交期：",
               returnRefund: "退货退款",
               onlyRefund: "仅退款",
               viewDetails: "查看详情",
               noOrder: "暂无相关订单",
               remark: "备注",
               orderType: {
                  "00": "实物订单",
                  "04": "项目订单",
                  "05": "礼包购买",
                  "06": "积分订单",
                  "07": "虚拟订单",
                  "08": "礼包兑换订单",
                  "09": "权益订单",
                  "10": "奖品兑换订单",
                  "11": "服务订单",
                  "12": "卡券订单"
               },
               dataState: ["已取消", "初始化", "待付款", "待发货", "待收货", "成功"],

            }
         },
         orderInfor: {
            step: ['选择订货商品', '提交订单', '订单审核', '订单转单', '订单分厂', '订单生产', '订单出库'],
            stepHy: ['选择订货商品', '提交订单', '待付款', '订单转单', '订单生产', '订单出库', '已完成'],
            orderNo: "订单编号：",
            orderState: "订单状态：",
            remark: "备注信息： ",
            changeNum: "转单数量",
            payDown: ['线下支付', '在线支付'],
            subInfo: "分厂信息",
            cancelOrder: "取消订单",
            atOncePay: "立即支付",
            viewLogistics: "查看物流",
            confirmGoods: "确认收货",
            toEvaluate: "去评价",
            yetCancel: "订单已取消",
            buyerOrder: "买家已下单",
            waitSeller: "等待卖家发货",
            sellerWait: "卖家已发货，待确认收货",
            complete: "交易完成",
            waitEvaluate: "交易完成，待评价",
            payFailer: "支付失败",
            paySuccess: "支付成功",
            address: "收货地址",
            orderTime: "下单时间",
            effectTime: "预计交期",
            payWay: "支付方式",
            onlinePay: "在线支付",
            logisticsMsg: "物流信息",
            No: "暂无",
            getGoodsMsg: "收货信息",
            payTime: "支付时间",
            goodsMsg: "商品信息",
            goodsMsgs: "商品清单",
            goodsName: "商品名称",
            price: "价格",
            quantity: "数量",
            discountAmount: "优惠金额",
            cashRatio: "现金占比",
            costRatio: "费用占比",
            totle: "小计",
            operation: "操作",
            refund: "退款",
            afterSales: "售后",
            goodsTotalAmount: "商品总金额:",
            freight: "运费",
            memberEquity: "会员权益",
            Payable: "应付款(含运费)",
            realPay: "实付款(含运费)",
            noNeedPay: "不需要付款",
            confirm: '确 定',
            cancel: '取 消',
            confirmGetGoods: "确认收货吗",
            confirmCancelOrder: "确认取消订单吗",
            noExpressMsg: "暂无快递信息"
         }
      }
   },
   order: {
      storderList: {
         plat: "平台",
         platPlaceholder: "请输入平台订单号",
         platOrder: "平台订单号",
         promptTitle: "确定是否重新推送平台数据？",
      },
      storeOrderList: {
         plat: "平台",
         erpName: "入机",
         platPlaceholder: "请输入平台订单号",
         platSltPlaceholder: "请选择平台",
         platOrder: "平台订单号",
         erpCode: "入机单号",
         erpDate: "入机时间",
         erpAmt: "入机金额",
      },
      apply: {
         num: '数量',
         refundMoney: '退款金额',
         refundEx: "填写退换理由",
         leixing: "类型",
         refundMon: "仅退款",
         refundMonAndthing: "退货退款",
         exchangeEx: "退换理由",
         placeholder: "请选择",
         memo: "请描述遇到的问题及详细的退换理由",
         uploadPic: "上传图片",
         detailMeo: "详细描述",
         submit: "提交申请",
         cancel: "取消",

      }
   },
   ysOrder: {
      ysproject: {
         London: '(UTC) 伦敦，卡萨布兰卡，协调世界时',
         Amsterdam: '(UTC+01:00) 阿姆斯特丹，柏林，维也纳，巴黎，马德里，中非西部',
         EasternEurope: '(UTC+02:00) 东欧，开罗，雅典，大马士革，耶路撒冷，伊斯坦布尔',
         Bagdad: '(UTC+03:00) 巴格达，科威特，加里宁格勒，内罗毕，莫斯科',
         Teheran: '(UTC+03:30) 德黑兰',
         AbuDhabi: '(UTC+04:00) 阿布扎比，埃里温，第比利斯，路易港',
         Kabul: '(UTC+04:30) 喀布尔',
         Tashkent: '(UTC+05:00) 塔什干，伊斯兰堡，卡拉奇，叶卡捷琳堡',
         Madras: '(UTC+05:30) 马德拉斯，孟买，新德里',
         Kathmandu: '(UTC+05:45) 加德满都',
         Almaty: '(UTC+06:00) 阿拉木图、达卡、鄂木斯克',
         Yangon: '(UTC+06:30) 仰光',
         Bangkok: '(UTC+07:00) 曼谷，河内，雅加达',
         Beijing: '(UTC+08:00) 北京，吉隆坡，新加坡，台北，伊尔库茨克',
         Osaka: '(UTC+09:00) 大阪，东京，首尔',
         Adelaide: '(UTC+09:30) 阿德莱德，达尔文',
         Vladivostok: '(UTC+10:00) 符拉迪沃斯托克，布里斯班，关岛，霍巴特，堪培拉，墨尔本，悉尼',
         SolomonIslands: '(UTC+11:00) 所罗门群岛，新喀里多尼亚',
         Auckland: '(UTC+12:00) 奥克兰，惠林顿，斐济，马加丹，协调世界时+12',
         Nukualof: '(UTC+13:00) 努库阿洛法',
         Cape: '(UTC-01:00) 佛得角群岛，亚速尔群岛',
         utc: '(UTC-02:00) 协调世界时-02，中大西洋',
         Brasilia: '(UTC-03:00) 巴西利亚，布宜诺斯艾利斯，格陵兰，萨尔瓦多',
         Newfoundland: '(UTC-03:30) 纽芬兰',
         Atlantic: '(UTC-04:00) 大西洋时间（加拿大），圣地亚哥，拉巴斯',
         Caracas: '(UTC-04:30) 加拉加斯',
         Eastern: '(UTC-05:00) 东部时间（美国和加拿大），波哥大，基多',
         Central: '(UTC-06:00) 中部时间（美国和加拿大），中美洲，墨西哥城',
         mountain: '(UTC-07:00) 山地时间（美国和加拿大），奇瓦瓦，亚利桑那',
         Pacific: '(UTC-08:00) 太平洋时间（美国和加拿大）',
         Alask: '(UTC-09:00) 阿拉斯加',
         Hawai: '(UTC-10:00) 夏威夷',
         Samoa: '(UTC-11:00) 萨摩亚群岛',
         west: '(UTC-12:00) 国际日期变更线西',
      }
   },
   integralPc: {
      index: {
         title: "优点",
         thead: ['流水号', '来源/用途', '优点变化', '日期'],
         nonePrompt: "暂无积分!"
      }
   },
   message: {
      list: {
         title: '系统通知',
         dataNone: '暂无消息',
         viewDetails: "查看详情"
      }
   },
   userPc: {
      certification: {
         title: "认证信息",
         selected: '请选择',
         state: '状态:',
         type: "类型:",
         realName: "会员名称:",
         companyName: "企业名称:",
         inArea: ["所在地区", "所在地区："],
         detailedAddress: ['详细地址：', '详细地址，街道、门牌号等'],
         legalName: "法人姓名:",
         IdNumber: "法人身份证号:",
         IdPicture: "法人身份证照片:",
         bankNumber: "银行卡号:",
         bankAddress: "开户银行:",
         sltBankAddress: "请输入开户银行:",
         holdPicture: "法人手持身份证照片:",
         positive: '正面（国徽面）',
         reverse: "反面（头像面）",
         imgTip: "（请上传jpg格式图片，不大于4M）",
         businessLicense: '企业营业执照:',
         ensure: "确认",
         cancel: "取消",
         validateIdPictureNone: "身份证正面和反面没有上传",
         validatePositiveError: "身份证正面没有上传",
         validateReverseError: "身份证反面没有上传",
         validateAddressNone: "请填写详细地址",
         validateNameNone: "请填写收货人",
         dataState: ["认证通过", "注册待审核", "未认证", "认证审核中", "认证失败"],
         userinfoType: {
            "1": "个人",
            "2": "公司"
         },
         companyAddress: "地址不能为空",
         userinfocompanyName: "企业名称不能为空",
         userinfoCorp: "法人姓名",
         userinfoCoid: ["身份证号不能为空", "身份证号格式不正确"],
         userinfoCertUrl: "需要上传营业执照",
         areaCode: '请选择地址',
         picError: "图片格式为常见的jpg、png、gif格式！",
         picError2: "上传的图片大小不能超过 5MB!",
         submitSuccess: '信息提交成功',
      },
      certificationzgl: {
         title: "认证信息",
         selected: '请选择',
         state: '状态:',
         type: "类型:",
         imgTip: "（请上传jpg格式图片，不大于4M）",
         realName: "会员名称:",
         companyName: "企业名称:",
         realName: "真实姓名:",
         iptrealName: "真实姓名:",
         inArea: ["所在地区", "所在地区："],
         detailedAddress: ['详细地址：', '详细地址，街道、门牌号等'],
         legalName: "法人姓名:",
         IdNumber: "法人身份证号:",
         IdPicture: "法人身份证照片:",
         bankNumber: "银行卡号:",
         sltbankNumber: "请输入银行卡号",
         bankAddress: "开户银行:",
         sltBankAddress: "请输入开户银行",
         holdPicture: "法人手持身份证照片:",
         positive: '正面',
         reverse: "反面",
         businessLicense: '企业营业执照:',
         ensure: "确认",
         cancel: "取消",
         validateIdPictureNone: "身份证正面和反面没有上传",
         validatePositiveError: "身份证正面没有上传",
         validateReverseError: "身份证反面没有上传",
         validateAddressNone: "请填写详细地址",
         validateNameNone: "请填写收货人",
         dataState: ["认证通过", "注册待审核", "未认证", "认证审核中", "认证失败"],
         userinfoType: {
            "1": "个人",
            "2": "公司"
         },
         companyAddress: "地址不能为空",
         userinfocompanyName: "请填写企业名称",
         userinfoCorp: "请填写法人姓名",
         userinfoCoid: ["请填写身份证号", "身份证号格式不正确"],
         userinfoCertUrl: "需要上传营业执照",
         userinfoCert1Url: "需要上传身份证正面照片",
         userinfoCert2Url: "需要上传身份证反面照片",
         userinfoCert3Url: "需要上传手持身份证照片",
         areaCode: '请选择地址',
         picError: "图片格式为常见的jpg、png、gif格式！",
         picError2: "上传的图片大小不能超过 5MB!",
         submitSuccess: '信息提交成功',
      },
      certificationDt: {
         title: "认证信息",
         selected: '请选择',
         placeholder: "请输入国家",
         placeholderT: "请输入地区",
         state: '状态:',
         type: "类型:",
         realName: "真实姓名:",
         inArea: ["所在地区", "所在地区："],
         detailedAddress: ['详细地址：', '详细地址，街道、门牌号等'],
         legalName: "法人姓名:",
         IdNumber: "身份证号:",
         IdPicture: "身份证照片:",
         positive: '正面',
         reverse: "反面",
         businessLicense: '营业执照:',
         ensure: "确认",
         cancel: "取消",
         validateIdPictureNone: "身份证正面和反面没有上传",
         validatePositiveError: "身份证正面没有上传",
         validateReverseError: "身份证反面没有上传",
         validateAddressNone: "请填写详细地址",
         validateNameNone: "请填写收货人",
         dataState: ["认证通过", "注册待审核", "未认证", "认证审核中", "认证失败"],
         userinfoType: {
            "1": "个人",
            "2": "公司"
         },
         companyAddress: "地址不能为空",
         userinfoCorp: "法人姓名",
         userinfoCoid: ["身份证号不能为空", "身份证号格式不正确"],
         userinfoCertUrl: "需要上传营业执照",
         areaCode: '请选择地址',
         picError: "图片格式为常见的jpg、png、gif格式！",
         picError2: "上传的图片大小不能超过 5MB!",
         submitSuccess: '信息提交成功',
      },
      modules: {
         auctionAng: {
            title: "我的竞价",
            seeBid: "查看全部竞价",
            bidNotice: "竞价公告名称",
            bidTime: "竞价时间",
            margin: "保证金:",
            bidState: "竞价状态:",
            operation: "操作",
            session: "场次号：",
            winBid: "成交",
            loseBid: "未成交",
            note: ["备注：", "备注：重新上传后需平台审核通过后才可参与竞价"],
            toPay: "去支付",
            toOffer: "去出价",
            viewDetails: "查看详情",
            resubmit: "重新提交",
            resubmitAptitude: "重新提交资质",
            uploadAptitude: "需要上传的资质：",
            aptitudePlace: "请上传资质",
            confirm: "确 定",
            cancel: '取 消',
            state: ['待交保', '待审核', '审核通过', '竞价中', '已结束'],
            uploadPicError: ["图片格式为常见的jpg、png、gif格式！", "上传的图片大小不能超过 2MB!"],
         },
         collection: {
            title: "我的收藏",
            all: "全部 >",
            message: ["未获取到路经", "已经是第一条了！", "没有更多了！"],
         },
         collectionAng: {
            title: "我的收藏",
            allCollect: "所有收藏 >",
            bidCollect: '竞价收藏',
            storeCollect: "店铺收藏",
            goodsCollect: "商品收藏",
            dataState: ["进行中", "待开始", "已结束"],
            margin: "保证金:",
            noCollect: "暂无收藏",
            message: "未获取到路经",
         },
         header: {
            member: "普通会员",
            changeProfile: "修改个人资料",
            lists: ["待支付", "待发货", "已完成", "已取消", "退换货"],
         },
         headerAng: {
            upSeller: "升级为卖家",
            changePwd: "修改密码",
            userIntegral: "用户积分：",
            myAccount: "我的账户",
            myMargin: "我的可用保证金：",
            viewDetails: "查看明细",
            allMargin: "全部保证金：",
            freezeMargin: "冻结保证金：",
            topUp: "充值",
            withdrawal: "提现",
            myMessage: "我的消息",
            systemMsg: "系统消息",
            consulting: "我的咨询",
            toSee: "去看看>",
            member: ["普通会员", "普通会员LV"]

         },
         headerUcc: {
            member: "普通会员",
            changeProfile: "修改个人资料",
            lists: ["待付款", "待发货", "待收货", "待评价"],
         },
         order: {
            title: "我的收藏",
            all: "全部",
            orderCode: "订单号",
            name: '收货人',
            orderState: "订单状态",
            money: "金额",
            operation: "操作",
            integral: "积分",
            dataState: ["审核", "待付款", "待发货", "已发货", "已完成", "交易成功", "已取消"],
            viewDetails: "查看详情",
            atOncePay: "立即支付",
            cancelOrder: '取消订单',
            yetCancel: "已取消",
            confirmGoods: "确认收货",
            viewLogistics: "查看物流",
            evaluation: ["已评价", "评价"],
            noOrder: "暂无订单",
            confirm: "确 定",
            cancel: '取 消',
            msgConfirmGoods: "确认收货吗",
            msgCancelOrder: "确认取消订单吗",
            msgError: "暂无快递信息",
         },
         orderUcc: {
            title: "我的收藏",
            all: "全部",
            orderCode: "订单号：",
            name: '收货人',
            orderState: "订单状态",
            money: "金额",
            operation: "操作",
            integral: "积分",
            dataState: ["审核", "待付款", "待发货", "待收货", "已完成", "交易成功", "已取消"],
            viewDetails: "查看详情",
            atOncePay: "立即支付",
            cancelOrder: '取消订单',
            yetCancel: "已取消",
            confirmGoods: "确认收货",
            viewLogistics: "查看物流",
            evaluation: ["已评价", "评价"],
            noOrder: "暂无订单",
            confirm: "确 定",
            cancel: '取 消',
            msgConfirmGoods: "确认收货吗",
            msgCancelOrder: "确认取消订单吗",
            msgError: "暂无快递信息",
         }
      }
   },
}