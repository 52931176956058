var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-button-group",
    _vm._l(_vm.tableConfig.tabs.enum, function (key, val) {
      return _c(
        "el-button",
        {
          key: val,
          staticClass: "btnTabs",
          attrs: {
            disabled: _vm.tableLoading,
            type: _vm.currentVal === val ? "primary" : "",
          },
          on: {
            click: function ($event) {
              return _vm.btnClick(val)
            },
          },
        },
        [_vm._v(_vm._s(key))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }